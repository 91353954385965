import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Box, Button, SvgIconProps } from '@material-ui/core';
import styled from 'styled-components';

const ContrastButton = styled(Button)`
  color: ${p => p.theme.palette.primary.contrastText};
  height: 64px;
  padding: ${p => p.theme.spacing(0, 3)};
`;

const LinkBox = styled(Box)<{ active: boolean }>`
  background-color: ${p => (p.active ? 'rgba(255,255,255,0.1)' : 'unset')};
`;

export function LinkButton({
  title,
  path,
  icon: Icon
}: {
  title?: string;
  path: string;
  icon: (props: SvgIconProps) => JSX.Element;
}) {
  const history = useHistory();
  const location = useLocation();

  const navigate = useCallback(() => {
    history.push(path);
  }, [path, history]);

  return (
    <LinkBox active={location.pathname.startsWith(path)}>
      <ContrastButton
        variant="text"
        onClick={navigate}
        startIcon={!!Icon && <Icon />}
      >
        {title}
      </ContrastButton>
    </LinkBox>
  );
}
