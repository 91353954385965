import React, { useContext, useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  AppBar,
  Toolbar,
  Box,
  ButtonBase,
  Hidden
} from '@material-ui/core';
import styled from 'styled-components';

import { AuthContext, useFile, useSpot } from 'framework';
import {
  CroppedImage,
  LinkButton,
  SignedInUserMenu,
  Spacer
} from '../components';

import { pages } from './pages';

import logo from '../images/logo.png';

const StyledToolbar = styled(Toolbar)`
  display: flex;
`;

const StyledCompanyName = styled(ButtonBase)`
  padding: ${p => p.theme.spacing(0.5, 1)};
`;

const StyledLogo = styled(Box)`
  margin-right: ${p => p.theme.spacing(2)}px;
  background-color: #fff;
  border-radius: 50%;
  width: 48px;
  height: 48px;
`;

export const Header = () => {
  const history = useHistory();
  const { isInitialized, isSignedIn, getGroups } = useContext(AuthContext);
  const { organization } = useParams<{ organization: string }>();
  const { t } = useTranslation();

  const { data, query } = useSpot();
  const initialized = isInitialized();

  useEffect(() => {
    if (!initialized) {
      return;
    }
    query(`org/${organization}`, {}, ['organizations', organization]);
  }, [organization, query, initialized]);

  const org = data?.organizations?.[organization];

  const { url: imageUrl } = useFile(org?.icon || '', organization);

  return (
    <AppBar position="fixed">
      <StyledToolbar>
        <StyledCompanyName
          onClick={() =>
            org ? history.push(`/${organization}`) : history.push('/')
          }
        >
          {org?.icon ? (
            <StyledLogo>
              <CroppedImage height={48} width={48} url={imageUrl} />
            </StyledLogo>
          ) : (
            <Box mr={2}>
              <img src={logo} alt={t('logo')} height={48} width={48} />
            </Box>
          )}
          <Hidden smDown>
            <Typography variant="h5">{org?.name || organization}</Typography>
          </Hidden>
        </StyledCompanyName>
        <Spacer />
        <Hidden smDown>
          {Object.values(pages)
            .filter(innerPage => !innerPage.hidden)
            .filter(innerPage => {
              if (!innerPage.authorized) {
                return true;
              }

              if (!isInitialized() || !isSignedIn()) {
                return false;
              }

              const userGroups = isInitialized() ? getGroups() : [];
              return innerPage.groups?.length
                ? innerPage.groups?.some(group => userGroups.includes(group))
                : true;
            })
            .map(innerPage => (
              <LinkButton
                key={innerPage.path}
                title={innerPage.title && t(innerPage.title)}
                path={`${innerPage.path
                  .replaceAll(':organization', organization)
                  .replaceAll(':userId', '')
                  .replaceAll(':tab?', '')}`}
                icon={innerPage.icon}
              />
            ))}
        </Hidden>
        {isInitialized() && isSignedIn() && <SignedInUserMenu />}
      </StyledToolbar>
    </AppBar>
  );
};
