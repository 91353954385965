import React from 'react';
import {
  CheckCircleRounded as ApprovedIcon,
  RemoveCircle as BlockedIcon,
  ErrorRounded as RejectedIcon,
  WatchLaterRounded as PendingIcon,
  PregnantWomanRounded as PregnancyIcon,
  BeachAccessRounded as VacationIcon,
  SupervisorAccountRounded as FamilyIcon,
  WorkOffRounded as GeneralLeaveIcon
} from '@material-ui/icons';

import { LeaveState } from 'domain-types';

export const getLeaveTypeIcon = (type: string) => {
  switch (type) {
    case 'family':
      return <FamilyIcon />;
    case 'pregnancy':
      return <PregnancyIcon />;
    case 'vacation':
      return <VacationIcon />;
    case 'bereavement':
    case 'military':
    case 'religious':
    case 'sick':
    default:
      return <GeneralLeaveIcon />;
  }
};

export const getLeaveIcon = (state: LeaveState) => {
  switch (state) {
    case 'rejected':
      return <RejectedIcon style={{ color: '#f09999' }} />;
    case 'requested':
      return <PendingIcon style={{ color: '#999999' }} />;
    case 'approved':
      return <ApprovedIcon style={{ color: '#99f099' }} />;
    case 'filed':
      return <ApprovedIcon style={{ color: '#999999' }} />;
    default:
      return <BlockedIcon style={{ color: '#999999' }} />;
  }
};
