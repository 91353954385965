import React, { ChangeEvent, useRef } from 'react';
import { Button, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Table } from 'components/table';
import { CustomDialog } from './custom-dialog';

const StyledButton = styled(Button)`
  margin: ${p => p.theme.spacing(2, 2)};
  padding: ${p => p.theme.spacing(2)}px;
`;

export interface ImportUsersUser {
  firstName?: string;
  lastName?: string;
  email?: string;
  dob?: string;
  startDate?: string;
}

interface Props {
  dialogOpen: boolean;
  onSubmit?: (users: ImportUsersUser[]) => Promise<void>;
  onClose?: () => unknown;
  acceptLabel?: string;
  rejectLabel?: string;
}

export function ImportUsersDialog(props: Props) {
  const fileInput = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  const renderControl = (
    setValue: (val?: ImportUsersUser[]) => void,
    value?: ImportUsersUser[]
  ) => {
    const handleFileSelected = (e: ChangeEvent<HTMLInputElement>) => {
      e.target.files?.length && processCsv(e.target.files[0]);
    };

    const processCsv = async (file: File) => {
      const fileText = await file.text();
      const lines = fileText.split('\n');
      const users = lines.map(line => {
        const fields = line.split(',');
        return {
          firstName: fields[0],
          lastName: fields[1] ?? '',
          email: fields[2],
          dob: fields[3] ?? '',
          startDate: fields[4] ?? ''
        };
      });
      setValue(users);
    };

    return (
      <>
        <Typography>{t('importUsersSubtitle1')}</Typography>
        <pre>{t('importUsersSubtitle2')}</pre>
        <Typography>{t('importUsersSubtitle3')}</Typography>
        <StyledButton
          variant="outlined"
          onClick={() => fileInput.current?.click()}
        >
          <Typography>{t('selectCsv')}</Typography>
        </StyledButton>
        <input
          ref={fileInput}
          type="file"
          onChange={handleFileSelected}
          style={{ display: 'none' }}
        />
        <Table
          headers={[
            t('firstName'),
            t('lastName'),
            t('email'),
            t('dob'),
            t('startDate')
          ]}
          rows={
            value?.map(user => [
              user.firstName,
              user.lastName,
              user.email,
              user.dob,
              user.startDate
            ]) ?? []
          }
        />
      </>
    );
  };

  return CustomDialog({
    ...props,
    title: t('importUsersTitle'),
    renderControl
  });
}
