import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  ChangeEvent
} from 'react';
import { useParams, useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  Button,
  IconButton,
  TextField,
  Typography,
  CircularProgress,
  Box
} from '@material-ui/core';
import {
  AddRounded as AddIcon,
  PeopleRounded as ImportIcon,
  ChevronRight as GoToUserIcon,
  ArrowBackRounded as BackIcon,
  DeleteRounded as DeleteIcon,
  CloudUploadRounded as UploadIcon,
  SearchRounded as SearchIcon
} from '@material-ui/icons';
import styled from 'styled-components';

import {
  AuthContext,
  baseUrl,
  useDebounce,
  useFeatureFlags,
  useSpot
} from 'framework';
import {
  ConfirmDialog,
  Line,
  Row,
  Spacer,
  Gap,
  Table,
  TitleContainer,
  UserResult,
  EditUserDialog,
  ImportUsersDialog
} from 'components';
import { UserInfo } from './user-info';

const SearchBox = styled(Box)`
  flex: 1 1 auto;
`;

interface EditUserDialogDesc {
  callback: (user: UserResult) => Promise<void>;
  firstName?: string;
  lastName?: string;
  dob?: string;
  email?: string;
  startDate?: string;
  managerId?: string;
  title?: string;
}

interface ConfirmDialogDesc {
  title: string;
  text: string;
  callback: () => Promise<void>;
  cancel?: () => unknown;
}

interface ImportUsersDialog {
  callback: () => Promise<void>;
}

function FileUploadButton({
  organization,
  id
}: {
  organization: string;
  id: string;
}) {
  const { t } = useTranslation();
  const { getToken } = useContext(AuthContext);
  const fileInput = useRef<HTMLInputElement>(null);
  const { command } = useSpot();

  const handleFileSelected = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.files?.length && uploadFile(e.target.files[0]);
  };

  const uploadFile = async (file: File) => {
    if (!file) {
      return;
    }

    // Fetch upload url
    const uploadResponse = await fetch(
      `${baseUrl}/org/${organization}/user/${id}/getUserFileUpload/${file.name}`,
      {
        headers: {
          authorization: getToken().getJwtToken()
        },
        method: 'get'
      }
    );

    const { url } = await uploadResponse.json();

    // Upload
    await fetch(url, {
      method: 'put',
      body: file
    });

    await command(`org/${organization}/user/${id}/notify`, {
      type: 'new-file',
      information: {
        filename: file.name
      }
    });
  };

  return (
    <IconButton
      onClick={() => fileInput.current?.click()}
      title={t('uploadFile')}
    >
      <UploadIcon />
      <input
        ref={fileInput}
        type="file"
        onChange={handleFileSelected}
        style={{ display: 'none' }}
      />
    </IconButton>
  );
}

export function Users() {
  const { t } = useTranslation();
  const history = useHistory();
  const { organization, userId } = useParams<{
    organization: string;
    userId: string;
  }>();
  const [editDialog, setEditDialog] = useState<EditUserDialogDesc | null>(null);
  const [
    importUsersDialog,
    setImportUsersDialog
  ] = useState<ImportUsersDialog | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState<ConfirmDialogDesc | null>(
    null
  );

  const { global } = useFeatureFlags();

  const { data, errors, query, command } = useSpot();

  const searchUsers = async (st: string) => {
    setLoading(true);
    await query(`org/${organization}/search`, { searchTerm: st }, [
      'searchUsers'
    ]);
    setLoading(false);
  };

  const debouncedSearch = useDebounce(searchUsers, 500);

  useEffect(() => {
    if (errors.find(err => err.status === 401)) {
      window.location.reload();
    }
  }, [errors]);

  useEffect(() => {
    debouncedSearch(searchTerm);
  }, [organization, query, searchTerm, debouncedSearch, userId]);

  const onAdd = () => {
    const showAddDialog = () =>
      setEditDialog({
        callback: async userResult => {
          await command(`org/${organization}/user`, { ...userResult });
          await debouncedSearch(searchTerm);
          setEditDialog(null);
        }
      });

    const currentUserCount = Object.values(data?.searchUsers || {}).length;
    if (currentUserCount === 14 || currentUserCount === 49) {
      setConfirmDialog({
        title: t('confirmPriceChangeTitle'),
        text: t('confirmPriceChangeSubtitle', {
          tier: t(currentUserCount === 14 ? 'startup' : 'large'),
          price: t(currentUserCount === 14 ? 'startupPrice' : 'largePrice')
        }),
        callback: async () => showAddDialog()
      });
    } else {
      showAddDialog();
    }
  };

  const onDelete = (id: string) => {
    setConfirmDialog({
      title: t('confirmUserDeleteTitle'),
      text: t('confirmUserDeleteSubtitle'),
      callback: async () => {
        await command(
          `org/${organization}/user/${id}`,
          {},
          { method: 'DELETE' }
        );
        await debouncedSearch(searchTerm);
        setConfirmDialog(null);
      }
    });
  };

  const onImport = () => {
    setImportUsersDialog({
      callback: async () => {
        await command(
          `org/${organization}/createUsers`,
          {},
          { method: 'POST' }
        );
        await debouncedSearch(searchTerm);
        setConfirmDialog(null);
      }
    });
  };

  const filteredUsers = (data?.searchUsers
    ? Object.values(data?.searchUsers)
    : []
  ).sort((userA, userB) => userA.firstName.localeCompare(userB.firstName));

  const selectUser = (id: string) => {
    history.push(`/${organization}/users/${id}`);
  };

  const deselectUser = () => {
    history.push(`/${organization}/users`);
  };

  return (
    <>
      {userId ? (
        <>
          <TitleContainer>
            <Typography variant="h5">
              <IconButton onClick={() => deselectUser()} title={t('back')}>
                <BackIcon />
              </IconButton>
              {`${data?.searchUsers?.[userId]?.firstName ?? ''} ${data
                ?.searchUsers?.[userId]?.lastName ?? ''}`}
            </Typography>
          </TitleContainer>
          <Line />

          <UserInfo id={userId} organization={organization} />
        </>
      ) : (
        <>
          <TitleContainer>
            <Typography variant="h5">{t('users')}</Typography>
          </TitleContainer>
          <Line />

          <Row mb={1}>
            <SearchBox>
              <TextField
                label={t('searchUser')}
                value={searchTerm}
                fullWidth
                variant="outlined"
                size="medium"
                onChange={(event: React.ChangeEvent<{ value: string }>) => {
                  setSearchTerm(event.target.value);
                }}
                InputProps={{
                  endAdornment: loading ? (
                    <CircularProgress size={24} />
                  ) : (
                    <SearchIcon color="action" />
                  )
                }}
              />
            </SearchBox>
            <Gap />
            <Button
              color="primary"
              startIcon={<AddIcon />}
              aria-label="add"
              onClick={onAdd}
            >
              {t('addUser')}
            </Button>
            {global?.batchImport && (
              <Button
                color="secondary"
                startIcon={<ImportIcon />}
                aria-label="import"
                onClick={onImport}
              >
                {t('importUsers')}
              </Button>
            )}
          </Row>
          <Table
            maxHeight="600px"
            headers={[
              t('email'),
              t('firstName'),
              t('lastName'),
              t('dob'),
              t('title'),
              t('manager'),
              ''
            ]}
            rows={filteredUsers.map(user => ({
              id: user.id,
              cells: [
                user.email,
                user.firstName,
                user.lastName ?? '',
                user.dob ?? '',
                user.title ?? '',
                user.managerId
                  ? `${data?.searchUsers?.[user.managerId]?.firstName ??
                      ''} ${data?.searchUsers?.[user.managerId]?.lastName ??
                      ''}`
                  : '',
                <Row key="icons">
                  <Spacer />
                  <IconButton
                    onClick={() => onDelete(user.id)}
                    title={t('delete')}
                  >
                    <DeleteIcon />
                  </IconButton>
                  <FileUploadButton organization={organization} id={user.id} />
                  <IconButton
                    onClick={() => selectUser(user.id)}
                    title={t('viewUser')}
                  >
                    <GoToUserIcon />
                  </IconButton>
                  <Spacer />
                </Row>
              ]
            }))}
          />
          <EditUserDialog
            organization={organization}
            title={t('userDialogTitle')}
            text={t('userDialogSubtitle')}
            initialValue={{
              firstName: editDialog?.firstName,
              lastName: editDialog?.lastName,
              dob: editDialog?.dob,
              email: editDialog?.email,
              startDate: editDialog?.startDate,
              managerId: editDialog?.managerId
            }}
            dialogOpen={!!editDialog}
            onClose={() => setEditDialog(null)}
            onSubmit={editDialog?.callback}
          />
          <ConfirmDialog
            title={confirmDialog?.title ?? ''}
            text={confirmDialog?.text ?? ''}
            dialogOpen={!!confirmDialog}
            onClose={() => setConfirmDialog(null)}
            onSubmit={confirmDialog?.callback}
          />
          <ImportUsersDialog
            dialogOpen={!!importUsersDialog}
            onClose={() => setImportUsersDialog(null)}
            onSubmit={importUsersDialog?.callback}
          />
        </>
      )}
    </>
  );
}
