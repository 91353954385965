import React, { useState } from 'react';
import { CssBaseline } from '@material-ui/core';
import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider
} from '@material-ui/core/styles';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import i18next from 'i18next';
import initLanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import { initializeSpot } from 'spot-store';

import { pages, Template } from 'pages';
import { theme } from 'theme';
import { ApplicationData, SpotContext } from 'framework';
import enUS from './translations/en-US.json';

function App() {
  const [state, setState] = useState<
    'uninitialized' | 'loading' | 'initialized'
  >('uninitialized');

  const spot = initializeSpot<ApplicationData>(
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:3001/api'
      : '/api',
    process.env.NODE_ENV === 'development'
  );

  const initialize = async () => {
    setState('loading');
    await i18next
      .use(initLanguageDetector)
      .use(initReactI18next)
      .init({
        fallbackLng: 'en-US',
        resources: {
          'en-US': enUS
        },
        debug: process.env.NODE_ENV === 'development',
        keySeparator: false,
        interpolation: {
          escapeValue: false
        }
      });

    setState('initialized');
  };

  if (state === 'uninitialized') {
    initialize();
  }

  if (state !== 'initialized') {
    return <>...</>;
  }

  return (
    <div className="App">
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <StyledThemeProvider theme={theme}>
          <StylesProvider injectFirst>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <SpotContext.Provider
                value={{
                  spot
                }}
              >
                <Router>
                  <Switch>
                    <Route
                      exact
                      path="/"
                      render={() => <Template page={pages.home} />}
                    />
                    {Object.values(pages).map(page => (
                      <Route
                        key={page.path}
                        exact
                        path={`${page.path}${
                          page.pathParams ? `/${page.pathParams.join('/')}` : ''
                        }`}
                        render={() => <Template page={page} />}
                      />
                    ))}
                    <Route
                      exact
                      path="*"
                      render={() => <Template page={pages.notFound} />}
                    />
                  </Switch>
                </Router>
              </SpotContext.Provider>
            </MuiPickersUtilsProvider>
          </StylesProvider>
        </StyledThemeProvider>
      </MuiThemeProvider>
    </div>
  );
}

export default App;
