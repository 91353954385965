import { createContext } from 'react';
import {
  CognitoAccessToken,
  CognitoAuth,
  CognitoAuthSession
} from 'amazon-cognito-auth-js';

declare global {
  interface Window {
    auth: {
      clientId: string;
      domain: string;
      poolId: string;
    };
  }
}

interface State {
  location?: string;
}

interface WellKnown {
  userPoolId: string;
  clientId: string;
  domain: string;
}

interface IdToken {
  auth_time: number;
  'cognito:groups': string[];
  'cognito:username': string;
  email: string;
  exp: number;
  iat: number;
  iss: string;
  sub: string;
  token_use: string;
}

let auth: CognitoAuth | undefined;

async function init(organization: string) {
  if (!auth) {
    const baseUrl =
      process.env.NODE_ENV === 'development'
        ? 'http://localhost:3001/api'
        : '/api';
    try {
      const response = await fetch(`${baseUrl}/org/${organization}/wellknown`);
      const wellKnown = (await response.json()) as WellKnown;
      auth = new CognitoAuth({
        ClientId: wellKnown.clientId,
        AppWebDomain: wellKnown.domain,
        UserPoolId: wellKnown.userPoolId,
        TokenScopesArray: ['openid', 'profile', 'email'],
        RedirectUriSignIn: `${window.location.origin}/callback/${organization}`,
        RedirectUriSignOut: `${window.location.origin}/callback/${organization}`,
        IdentityProvider: 'COGNITO',
        AdvancedSecurityDataCollectionFlag: false
      });
      auth.userhandler = {
        onSuccess: (authSession: CognitoAuthSession) => {
          if (!auth) {
            throw new Error('Auth not loaded yet, call init first');
          }

          // For some reason it screws this up.
          auth.setState(decodeURIComponent(authSession.getState()));

          const tryGetLocation = (a: CognitoAuth) => {
            try {
              const state = JSON.parse(atob(a.getState()));
              return state.location;
            } catch (err) {
              console.error(err);
              return window.location.href.includes('/callback/')
                ? `${window.location.origin}/${organization}`
                : window.location.href;
            }
          };
          window.location.href =
            tryGetLocation(auth) ?? `${window.location.origin}/${organization}`;
        },
        onFailure: (err: unknown) => {
          // eslint-disable-next-line no-console
          console.error('Error handling auth token update', err);

          if (!auth) {
            throw new Error('Auth not loaded yet, call init first');
          }

          auth.clearCachedTokensScopes();
          window.location.href = `${window.location.origin}/${organization}`;
        }
      };
    } catch (e) {
      window.location.href = `${window.location.origin}/${organization}/notFound`;
    }
  }
}

async function requestSignin() {
  if (!auth) {
    throw new Error('Auth not loaded yet, call init first');
  }
  auth.useCodeGrantFlow();
  auth.setState(btoa(JSON.stringify({ location: window.location.href })));
  auth.getSession();
}

function handleCallback() {
  if (!auth) {
    throw new Error('Auth not loaded yet, call init first');
  }
  auth.parseCognitoWebResponse(window.location.href);
}

export const AuthContext = createContext<{
  isSignedIn: () => boolean;
  requestSignin: () => unknown;
  handleCallback: () => void;
  getState: () => State;
  getToken: () => CognitoAccessToken;
  isInitialized: () => boolean;
  init: (organization: string) => Promise<void>;
  getCurrentUser: () => string;
  isAdministrator: () => boolean;
  getGroups: () => string[];
}>({
  init,
  isInitialized: () => !!auth,
  isSignedIn: () => {
    if (!auth) {
      throw new Error('Auth not loaded yet, call init first');
    }

    return auth.isUserSignedIn();
  },
  requestSignin,
  handleCallback,
  getState: () => {
    if (!auth) {
      throw new Error('Auth not loaded yet, call init first');
    }

    return (auth.getState() ? JSON.parse(atob(auth.getState())) : {}) as State;
  },
  getToken: () => {
    if (!auth) {
      throw new Error('Auth not loaded yet, call init first');
    }
    return auth.getCachedSession().getAccessToken();
  },
  getCurrentUser: () => {
    if (!auth) {
      throw new Error('Auth not loaded yet, call init first');
    }
    return auth.getCurrentUser();
  },
  isAdministrator: () => {
    if (!auth) {
      throw new Error('Auth not loaded yet, call init first');
    }
    const idToken = auth
      .getCachedSession()
      .getIdToken()
      .decodePayload();
    const groups = (idToken as IdToken)['cognito:groups'] as string[];
    return !!groups?.includes('administrators');
  },
  getGroups: () => {
    if (!auth) {
      throw new Error('Auth not loaded yet, call init first');
    }
    const idToken = auth
      .getCachedSession()
      .getIdToken()
      .decodePayload();
    const groups = (idToken as IdToken)['cognito:groups'] as string[];
    return groups || [];
  }
});
