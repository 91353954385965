import React, { useContext } from 'react';
import { useParams, useHistory } from 'react-router';

import { Container, Gap } from 'components';
import { AuthContext } from 'framework';

import { UserInfo } from './user-info';

export function User() {
  const { organization, tab } = useParams<{
    organization: string;
    tab?: string;
  }>();
  const { getCurrentUser } = useContext(AuthContext);

  const history = useHistory();

  const handleTabChange = (selectedTab: string) => {
    history.replace(`/${organization}/user/${selectedTab}`);
  };

  const id = getCurrentUser();

  return (
    <Container>
      <UserInfo
        id={id}
        organization={organization}
        tab={tab}
        onTabChange={handleTabChange}
        disabledTabs={['hourConfig', 'leaveConfig']}
      />
      <Gap />
    </Container>
  );
}
