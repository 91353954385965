import React from 'react';
import styled from 'styled-components';
import { Snackbar } from '@material-ui/core';
import { Alert, AlertTitle, Color } from '@material-ui/lab';

const StyledAlertTitle = styled(AlertTitle)`
  text-transform: capitalize;
`;

type Props = {
  onClose: () => void;
  message?: string;
  severity?: Color;
  title?: string;
};

export function Notification({
  onClose,
  message = '',
  severity = 'info',
  title
}: Props) {
  return (
    <Snackbar
      open
      autoHideDuration={15000}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
    >
      <Alert severity={severity}>
        <StyledAlertTitle>{title || severity}</StyledAlertTitle>
        {message}
      </Alert>
    </Snackbar>
  );
}
