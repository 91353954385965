import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { AuthContext } from 'framework';

export function Callback() {
  const { organization } = useParams<{ organization: string }>();
  const [authState, setAuthState] = useState<
    'uninitialized' | 'pending' | 'initialized'
  >('uninitialized');

  const { isInitialized, init, handleCallback } = useContext(AuthContext);

  useEffect(() => {
    if (authState !== 'initialized') {
      return;
    }
    handleCallback();
  }, [handleCallback, authState]);

  if (!isInitialized()) {
    const initAuth = async () => {
      setAuthState('pending');
      await init(organization);
      setAuthState('initialized');
    };

    if (authState === 'uninitialized') {
      initAuth();
    }

    return <>Setting up...</>;
  }

  return <>Loading...</>;
}
