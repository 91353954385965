import { createContext, useCallback, useContext, useState } from 'react';
import { Spot, Error } from 'spot-store';

import { AuthContext } from './auth-context';
import { Org, User, LeaveRequest } from '../domain-types';

export interface ApplicationData {
  organizations?: Record<string, Org>;
  users?: Record<string, User>;
  searchUsers?: Record<string, User>;
  actions?: {
    leave?: LeaveRequest[];
  };
  loading: boolean;
}

export const SpotContext = createContext<{
  spot: Spot<ApplicationData>;
}>({
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  spot: undefined!
});

export const useSpot = () => {
  const { spot } = useContext(SpotContext);
  const [data, setData] = useState<ApplicationData>({ ...spot.data });
  const [loading, setLoading] = useState(spot.data.loading);
  const [errors, setErrors] = useState<Error[]>([...spot.errors]);
  const { getToken } = useContext(AuthContext);

  // Sub to loading changes
  spot.store.subscribe(() => {
    setLoading(spot.data.loading);
  });

  const query = useCallback(
    async (
      endpoint: string,
      params: Record<string, unknown>,
      storagePath: string[],
      config?: { method: string | undefined }
    ) => {
      const oldErrors = [...spot.errors];

      await spot.query(endpoint, params ?? {}, storagePath, {
        ...config,
        authorization: getToken().getJwtToken()
      });
      setData({
        ...spot.data
      });

      const newErrors = spot.errors.slice(oldErrors.length);
      setErrors([...spot.errors]);

      if (newErrors.find(err => err.status === 401)) {
        window.location.reload();
      }

      if (newErrors.length) {
        // eslint-disable-next-line no-throw-literal
        throw [...newErrors];
      }
    },
    [spot, getToken, setData, setErrors]
  );

  const command = useCallback(
    async (
      endpoint: string,
      params?: Record<string, unknown>,
      config?: { method: string | undefined }
    ) => {
      const oldErrors = [...spot.errors];

      await spot.command(endpoint, params || {}, {
        ...config,
        authorization: getToken().getJwtToken()
      });

      const newErrors = spot.errors.slice(oldErrors.length);
      setErrors([...spot.errors]);

      if (newErrors.find(err => err.status === 401)) {
        window.location.reload();
      }

      if (newErrors.length) {
        // eslint-disable-next-line no-throw-literal
        throw [...newErrors];
      }
    },
    [spot, getToken, setErrors]
  );

  return { spot, data, errors, query, command, loading };
};
