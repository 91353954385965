import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Box, Hidden, IconButton, Typography } from '@material-ui/core';
import moment from 'moment';
import {
  CheckCircleRounded as ApproveIcon,
  CancelRounded as RejectIcon
} from '@material-ui/icons';

import styled from 'styled-components';

import noActionsImg from 'images/zen.jpg';
import { LeaveRequest } from 'domain-types';

import {
  ConfirmDialog,
  getLeaveIcon,
  getLeaveTypeIcon,
  Line,
  Table,
  LimitedText,
  TitleContainer,
  CollapsableTableRow,
  FormRow,
  FormLabel,
  FormTextValue
} from 'components';
import { AuthContext, useSpot } from 'framework';

const NoActionsBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CenterButtons = styled(Box)`
  display: flex;
  justify-content: center;
`;

interface ConfirmDialogDesc {
  title: string;
  text: string;
  callback: () => Promise<void>;
}

export function Actions() {
  const { t } = useTranslation();
  const { organization } = useParams<{ organization: string }>();
  const { getCurrentUser } = useContext(AuthContext);
  const [confirmDialog, setConfirmDialog] = useState<ConfirmDialogDesc | null>(
    null
  );
  const [loading, setLoading] = useState(false);

  const id = getCurrentUser();
  const { data, errors, query, command } = useSpot();

  useEffect(() => {
    if (errors.find(err => err.status === 401)) {
      window.location.reload();
    }
  }, [errors]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await query(`org/${organization}/user/${id}/leaveApproval`, {}, [
        'actions',
        'leave'
      ]);
      setLoading(false);
    })();
  }, [organization, id, query]);

  const onApprove = (leaveRequest: LeaveRequest) => {
    setConfirmDialog({
      title: t('approveLeaveTitle'),
      text: t('approveLeaveText'),
      callback: async () => {
        await command(
          `org/${organization}/user/${leaveRequest.userId}/leave/${leaveRequest.id}/approve`
        );
        await query(`org/${organization}/user/${id}/leaveApproval`, {}, [
          'actions',
          'leave'
        ]);
        setConfirmDialog(null);
      }
    });
  };

  const onReject = (leaveRequest: LeaveRequest) => {
    setConfirmDialog({
      title: t('rejectLeaveTitle'),
      text: t('rejectLeaveText'),
      callback: async () => {
        await command(
          `org/${organization}/user/${leaveRequest.userId}/leave/${leaveRequest.id}/reject`
        );
        await query(`org/${organization}/user/${id}/leaveApproval`, {}, [
          'actions',
          'leave'
        ]);
        setConfirmDialog(null);
      }
    });
  };

  return (
    <>
      <TitleContainer>
        <Typography variant="h5">{t('actions')}</Typography>
      </TitleContainer>
      <Line />
      <Hidden mdUp>
        <Table
          maxHeight="600px"
          headers={[t('status'), t('type'), t('name'), '']}
          renderEmptyContent={() => (
            <NoActionsBox>
              {loading ? (
                t('loading')
              ) : (
                <>
                  <img src={noActionsImg} height={400} alt={t('noActions')} />
                  <Typography variant="h5" color="primary">
                    {t('noActions')}
                  </Typography>
                </>
              )}
            </NoActionsBox>
          )}
          renderCustomRow={(leaveRequest: LeaveRequest) => (
            <CollapsableTableRow
              key={leaveRequest.id}
              id={leaveRequest.id}
              disabled={leaveRequest.state !== 'requested'}
              row={[
                <Box key="state" title={t(leaveRequest.state)}>
                  {getLeaveIcon(leaveRequest.state)}
                </Box>,
                <Box key="type" title={t(leaveRequest.type)}>
                  {getLeaveTypeIcon(leaveRequest.type)}
                </Box>,
                `${leaveRequest.firstName} ${leaveRequest.lastName ?? ''}`,
                ''
              ]}
              collapsedContent={
                <Box>
                  <FormRow>
                    <FormLabel>{t('reason')}</FormLabel>
                    <FormTextValue>{leaveRequest.reason}</FormTextValue>
                  </FormRow>
                  <FormRow>
                    <FormLabel>{t('when')}</FormLabel>
                    <FormTextValue>
                      {`${moment(leaveRequest.startDate).format(
                        'LL'
                      )} - ${moment(leaveRequest.endDate).format('LL')}`}
                    </FormTextValue>
                  </FormRow>
                  <CenterButtons>
                    <IconButton onClick={() => onApprove(leaveRequest)}>
                      <ApproveIcon style={{ color: '#55f055' }} />
                    </IconButton>
                    <IconButton onClick={() => onReject(leaveRequest)}>
                      <RejectIcon style={{ color: '#f05555' }} />
                    </IconButton>
                  </CenterButtons>
                </Box>
              }
            />
          )}
          rows={
            data?.actions?.leave
              ? data?.actions?.leave?.sort((reqA, reqB) => {
                  if (reqB.state !== 'requested') {
                    return -1;
                  }
                  if (reqA.state !== 'requested') {
                    return 1;
                  }

                  return reqB.startDate.localeCompare(reqA.startDate);
                })
              : []
          }
        />
      </Hidden>
      <Hidden smDown>
        <Table
          maxHeight="600px"
          headers={[
            t('status'),
            t('type'),
            t('name'),
            t('info'),
            t('start'),
            t('end'),
            ''
          ]}
          renderEmptyContent={() => (
            <NoActionsBox>
              {loading ? (
                t('loading')
              ) : (
                <>
                  <img src={noActionsImg} height={400} alt={t('noActions')} />
                  <Typography variant="h5" color="primary">
                    {t('noActions')}
                  </Typography>
                </>
              )}
            </NoActionsBox>
          )}
          rows={
            data?.actions?.leave
              ? data?.actions?.leave
                  ?.sort((reqA, reqB) => {
                    if (reqB.state !== 'requested') {
                      return -1;
                    }
                    if (reqA.state !== 'requested') {
                      return 1;
                    }

                    return reqB.startDate.localeCompare(reqA.startDate);
                  })
                  .map(leaveRequest => ({
                    id: leaveRequest.id,
                    disabled: leaveRequest.state !== 'requested',
                    cells: [
                      <Box key="state" title={t(leaveRequest.state)}>
                        {getLeaveIcon(leaveRequest.state)}
                      </Box>,
                      <Box key="type" title={t(leaveRequest.type)}>
                        {getLeaveTypeIcon(leaveRequest.type)}
                      </Box>,
                      `${leaveRequest.firstName} ${leaveRequest.lastName ??
                        ''}`,
                      <LimitedText
                        key="reason"
                        title={leaveRequest.reason}
                        variant="body2"
                      >
                        {leaveRequest.reason}
                      </LimitedText>,
                      moment(leaveRequest.startDate).format('LL'),
                      moment(leaveRequest.endDate).format('LL'),
                      <>
                        <IconButton onClick={() => onApprove(leaveRequest)}>
                          <ApproveIcon style={{ color: '#55f055' }} />
                        </IconButton>
                        <IconButton onClick={() => onReject(leaveRequest)}>
                          <RejectIcon style={{ color: '#f05555' }} />
                        </IconButton>
                      </>
                    ]
                  }))
              : []
          }
        />
      </Hidden>
      <ConfirmDialog
        title={confirmDialog?.title ?? ''}
        text={confirmDialog?.text ?? ''}
        dialogOpen={!!confirmDialog}
        onClose={() => setConfirmDialog(null)}
        onSubmit={confirmDialog?.callback}
      />
    </>
  );
}
