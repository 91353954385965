import React, { ReactChild, useEffect, useState } from 'react';
import {
  Box,
  MenuItem,
  Select,
  TextField,
  Typography,
  Slider
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@material-ui/pickers';
import { CalendarTodayRounded as CalenderIcon } from '@material-ui/icons';
import moment from 'moment';
import { addWeekDays, isWeekendDay } from 'moment-business';
import styled from 'styled-components';
import { PublicHoliday } from 'domain-types';

import { Gap, Spacer } from 'components/spacer';
import { Row, Container } from 'components/layout';
import { Alert } from '@material-ui/lab';
import { CustomDialog } from './custom-dialog';

import leaveSliderTip from '../../images/leave-slider-tip.gif';

const StyledSliderRow = styled(Row)`
  justify-content: center;
`;

const StyledSliderContainer = styled(Box)`
  margin: ${p => p.theme.spacing(2)}px;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 32px;
  white-space: nowrap;
  text-align: center;
  height: 80px;
`;

const StyledSliderNumber = styled(Typography)`
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 12px);
  width: 24px;
  height: 24px;
  font-size: 0.8em;
  padding: 4px;
  border-radius: 12px;
  text-align: center;
  background-color: ${p => p.theme.palette.background.paper};
  user-select: none;
  pointer-events: none;
`;

const StyledSlider = styled(Slider)`
  width: 32px !important;
  padding: 0px !important;

  .MuiSlider-mark {
    width: 32px;
  }

  .MuiSlider-thumb.Mui-disabled {
    display: none;
  }

  .MuiSlider-thumb {
    width: 32px;
    height: 2px;
    margin-bottom: -1px;
    margin-left: 0px;
    border-radius: 0;
    background-color: ${p => p.theme.palette.secondary.main};
  }

  .MuiSlider-track {
    width: 32px;
    border-radius: 0;
  }

  .MuiSlider-rail {
    width: 32px;
    border-radius: 0;
  }
`;

const StyledDateRow = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;
`;

const StyledDatePicker = styled(DatePicker)`
  flex: 1 1 auto;
`;

const TipGif = styled(Box)`
  margin: ${p => p.theme.spacing(2, 2)};
  display: flex;
  justify-content: center;
`;

interface HourSliderProps {
  maxHours: number;
  onHoursChanged: (hours: number) => unknown;
  caption: string;
  disabled: boolean;
}

function HourSlider({
  maxHours,
  onHoursChanged,
  caption,
  disabled
}: HourSliderProps) {
  const [currentHours, setCurrentHours] = useState(maxHours);
  return (
    <StyledSliderContainer>
      <StyledSlider
        defaultValue={maxHours}
        step={1}
        orientation="vertical"
        valueLabelDisplay="off"
        disabled={disabled}
        min={0}
        max={maxHours}
        onChange={(event: unknown, value: number | number[]) =>
          setCurrentHours(value as number)
        }
        onChangeCommitted={(event: unknown, value: number | number[]) => {
          onHoursChanged(value as number);
        }}
      />
      <StyledSliderNumber>{currentHours}</StyledSliderNumber>
      <Typography variant="caption">{caption}</Typography>
    </StyledSliderContainer>
  );
}

export interface LeaveRequestResult {
  startDate?: string;
  endDate?: string;
  hours?: Record<string, number>;
  type?: string;
  reason?: string;
}

interface Props {
  title: string;
  text?: string;
  leaveTypes: string[];
  hoursPerDay: number;
  initialValue?: LeaveRequestResult;
  publicHolidays: PublicHoliday[];
  dialogOpen: boolean;
  onSubmit?: (leaveRequest: LeaveRequestResult) => Promise<void>;
  onClose?: () => unknown;
}

export const RequestLeaveDialog = (props: Props) => {
  const { t } = useTranslation();
  const [validated, setValidated] = useState(false);
  useEffect(() => {
    setValidated(false);
  }, [props.dialogOpen]);

  const onValidate = (value?: LeaveRequestResult) => {
    setValidated(true);

    if (!value?.startDate || !value.endDate || !value.type || !value.hours)
      return false;

    return true;
  };

  const renderControl = (
    setValue: (val?: LeaveRequestResult) => void,
    value?: LeaveRequestResult
  ) => {
    const getResetHours = (startDate: string, endDate: string) => {
      const newHours: Record<string, number> = {};
      let current = moment(startDate);
      if (isWeekendDay(current)) {
        current = addWeekDays(current, 1);
      }
      const endDatePlusOne = moment(endDate).add(1, 'day');

      while (current.isBefore(endDatePlusOne)) {
        const currentDate = current.format('YYYY-MM-DD');
        const isPublicHoliday = !!publicHolidays.find(
          holiday =>
            moment(holiday.date, 'DD-MM').format('YYYY-MM-DD') === currentDate
        );

        newHours[currentDate] = isPublicHoliday ? 0 : hoursPerDay;
        current = addWeekDays(current, 1);
      }

      return newHours;
    };

    const handleStartDateChange = (startDate: string) => {
      let newValue = { ...value, startDate };

      if (value?.endDate) {
        newValue = {
          ...newValue,
          hours: getResetHours(startDate, value.endDate)
        };
      }
      setValue(newValue);
    };

    const handleEndDateChange = (endDate: string) => {
      let newValue = { ...value, endDate };

      if (value?.startDate) {
        newValue = {
          ...newValue,
          hours: getResetHours(value.startDate, endDate)
        };
      }
      setValue(newValue);
    };

    const handleReasonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue({ ...value, reason: e.target.value });
    };

    const handleTypeChange = (e: React.ChangeEvent<{ value: unknown }>) => {
      setValue({ ...value, type: e.target.value as string });
    };

    const totalHours =
      Object.values(value?.hours ?? {}).reduce(
        (sum, current) => sum + current,
        0
      ) ?? 0;

    const { text, leaveTypes, hoursPerDay, publicHolidays } = props;

    const renderSliders = (startDate: string, endDate: string) => {
      let current = moment(startDate);
      if (isWeekendDay(current)) {
        current = addWeekDays(current, 1);
      }

      const endDatePlusOne = moment(endDate).add(1, 'day');
      let y = 0;
      const renderList = [] as ReactChild[][];
      renderList.push([] as ReactChild[]);

      while (current.isBefore(endDatePlusOne)) {
        const currentDate = current.format('YYYY-MM-DD');
        const isPublicHoliday = !!publicHolidays.find(
          holiday =>
            moment(holiday.date, 'DD-MM').format('YYYY-MM-DD') === currentDate
        );

        renderList[y].push(
          <HourSlider
            key={`${current.format('L')}-${startDate}-${endDate}`}
            maxHours={isPublicHoliday ? 0 : hoursPerDay}
            disabled={isPublicHoliday}
            onHoursChanged={(hours: number) => {
              const newHours = { ...(value?.hours ?? {}) };
              newHours[currentDate] = hours;
              setValue({ ...value, hours: newHours });
            }}
            caption={current.format('D MMM')}
          />
        );

        const next = moment(current).add(1, 'day');
        if (isWeekendDay(next)) {
          y++;
          renderList.push([]);
        }

        current = addWeekDays(current, 1);
      }
      return renderList;
    };

    const renderSliderRow = (row: ReactChild[]) => (
      <StyledSliderRow>{row}</StyledSliderRow>
    );

    return (
      <>
        {!!text && <Typography>{text}</Typography>}
        <Gap />
        <Select
          onChange={handleTypeChange}
          value={value?.type || leaveTypes[0]}
          fullWidth
          autoFocus
        >
          {leaveTypes.map(option => (
            <MenuItem key={option} value={option}>
              {t(option)}
            </MenuItem>
          ))}
        </Select>
        <TextField
          error={validated && !value?.reason}
          value={value?.reason}
          onChange={handleReasonChange}
          placeholder={t('enterReason')}
          margin="dense"
          label={t('reason')}
          type="text"
          fullWidth
        />
        <StyledDateRow>
          <StyledDatePicker
            value={
              value?.startDate ? moment(value?.startDate, 'YYYY-MM-DD') : null
            }
            format="LL"
            margin="dense"
            onChange={v => handleStartDateChange(v?.format('YYYY-MM-DD') ?? '')}
            showTodayButton
            maxDate={value?.endDate ?? undefined}
            placeholder={t('enterStartDate')}
            label={value?.startDate ? ' ' : t('startDate')}
            error={validated && !value?.startDate}
            required={!value?.startDate}
            InputProps={{
              endAdornment: <CalenderIcon color="action" fontSize="small" />
            }}
          />
          <Gap />
          {t('to')}
          <Gap />
          <StyledDatePicker
            value={value?.endDate ? moment(value?.endDate, 'YYYY-MM-DD') : null}
            format="LL"
            margin="dense"
            onChange={v => handleEndDateChange(v?.format('YYYY-MM-DD') ?? '')}
            showTodayButton
            minDate={value?.startDate ?? undefined}
            placeholder={t('enterEndDate')}
            label={value?.endDate ? ' ' : t('endDate')}
            error={validated && !value?.endDate}
            required={!value?.endDate}
            InputProps={{
              endAdornment: <CalenderIcon color="action" fontSize="small" />
            }}
          />
        </StyledDateRow>
        {value?.startDate && value?.endDate && (
          <>
            <StyledDateRow>
              <Spacer />
              <Typography variant="subtitle1">
                {`${t('total')}: ${totalHours / hoursPerDay} ${t(
                  'days'
                )} (${totalHours} ${t('hours')})`}
              </Typography>
            </StyledDateRow>
            <Gap size={4} />
            <Container>
              {renderSliders(value.startDate, value.endDate).map(
                renderSliderRow
              )}
              <Alert severity="info">
                {t('leaveDragToAdjust')}
                <TipGif m={2}>
                  <img
                    src={leaveSliderTip}
                    alt={t('tip')}
                    height={80}
                    width={131}
                  />
                </TipGif>
              </Alert>
            </Container>
          </>
        )}
      </>
    );
  };

  return CustomDialog({ ...props, renderControl, onValidate });
};
