import React, { useCallback, useEffect, useState } from 'react';
import { TextField, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { Autocomplete } from '@material-ui/lab';
import { CalendarTodayRounded as CalenderIcon } from '@material-ui/icons';

import { Gap } from 'components/spacer';
import { User } from 'domain-types';
import { useSpot, validateEmail } from 'framework';

import { CustomDialog } from './custom-dialog';

export interface UserResult {
  firstName?: string;
  lastName?: string;
  email?: string;
  dob?: string;
  startDate?: string;
  managerId?: string;
  title?: string;
}

interface Props {
  organization: string;
  title: string;
  text?: string;
  initialValue?: UserResult;
  dialogOpen: boolean;
  onSubmit?: (user: UserResult) => Promise<void>;
  onClose?: () => unknown;
}

export const EditUserDialog = (props: Props) => {
  const { t } = useTranslation();

  const { data, query } = useSpot();

  const [validated, setValidated] = useState(false);

  const refresh = useCallback(async () => {
    await query(`org/${props.organization}/search`, {}, ['searchUsers']);
  }, [props.organization, query]);

  useEffect(() => {
    setValidated(false);
    refresh();
  }, [props.dialogOpen, refresh]);

  const onValidate = (value?: UserResult) => {
    setValidated(true);

    if (!value?.email || !value.firstName) return false;

    if (!validateEmail(value?.email)) {
      return false;
    }

    return true;
  };

  const renderControl = (
    setValue: (val?: UserResult) => void,
    value?: UserResult
  ) => {
    const handleDobChange = (dob: string) => {
      setValue({ ...value, dob });
    };

    const handleStartDateChange = (startDate: string) => {
      setValue({ ...value, startDate });
    };

    const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue({ ...value, lastName: e.target.value });
    };

    const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue({ ...value, firstName: e.target.value });
    };

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue({ ...value, email: e.target.value });
    };

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue({ ...value, title: e.target.value });
    };

    const { initialValue, text } = props;

    return (
      <>
        {!!text && <Typography>{text}</Typography>}
        <Gap />
        <TextField
          required={!initialValue?.email}
          disabled={!!initialValue?.email}
          error={validated && !value?.email}
          value={value?.email}
          onChange={handleEmailChange}
          placeholder={t('enterEmail')}
          margin="dense"
          autoFocus
          label={t('email')}
          type="text"
          fullWidth
        />
        <TextField
          required
          error={validated && !value?.firstName}
          value={value?.firstName}
          onChange={handleFirstNameChange}
          placeholder={t('enterFirstName')}
          margin="dense"
          label={t('firstName')}
          type="text"
          fullWidth
        />
        <TextField
          error={validated && !value?.lastName}
          value={value?.lastName}
          onChange={handleLastNameChange}
          placeholder={t('enterLastName')}
          margin="dense"
          label={t('lastName')}
          type="text"
          fullWidth
        />
        <DatePicker
          value={value?.dob ? moment(value?.dob, 'DD-MM-YYYY') : null}
          margin="dense"
          openTo="year"
          format="LL"
          disableFuture
          onChange={v => handleDobChange(v?.format('DD-MM-YYYY') ?? '')}
          fullWidth
          showTodayButton
          placeholder={t('enterDob')}
          label={value?.dob ? ' ' : t('dob')}
          error={validated && !value?.dob}
          InputProps={{
            endAdornment: <CalenderIcon color="action" fontSize="small" />
          }}
        />
        <DatePicker
          value={
            value?.startDate ? moment(value?.startDate, 'DD-MM-YYYY') : null
          }
          margin="dense"
          format="LL"
          onChange={v => handleStartDateChange(v?.format('DD-MM-YYYY') ?? '')}
          fullWidth
          showTodayButton
          placeholder={t('enterStartDate')}
          label={value?.startDate ? ' ' : t('startDate')}
          error={validated && !value?.startDate}
          InputProps={{
            endAdornment: <CalenderIcon color="action" fontSize="small" />
          }}
        />
        <Autocomplete
          value={
            value?.managerId ? data?.searchUsers?.[value?.managerId] : undefined
          }
          onChange={(event: unknown, newValue: User | null) => {
            setValue({ ...value, managerId: newValue?.id ?? '' });
          }}
          options={Object.values(data?.searchUsers || [])}
          getOptionLabel={(user: User) =>
            `${user.firstName} ${user.lastName ?? ''}`
          }
          autoHighlight
          getOptionSelected={(o: User, v: User) => o?.id === v?.id}
          fullWidth
          renderInput={(params: unknown) => (
            <TextField {...params} label={t('manager')} />
          )}
        />
        <TextField
          error={validated && !value?.title}
          value={value?.title}
          onChange={handleTitleChange}
          placeholder={t('title')}
          margin="dense"
          label={t('title')}
          type="text"
          fullWidth
        />
      </>
    );
  };

  return CustomDialog({ ...props, renderControl, onValidate });
};
