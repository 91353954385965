import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSpot } from 'framework';
import {
  FormLabel,
  FormRow,
  Line,
  TitleContainer,
  Capitalized,
  Gap,
  FormNumberInput
} from 'components';

export function HourConfiguration({
  id,
  organization
}: {
  id: string;
  organization: string;
}) {
  const { t } = useTranslation();
  const { data, command, query } = useSpot();

  const user = data?.users?.[id];

  useEffect(() => {
    (async () => {
      await query(`org/${organization}/user/${id}`, {}, ['users', id]);
    })();
  }, [query, organization, id]);

  const updateUser = async (field: string, value: unknown) => {
    await command(`org/${organization}/user/${id}`, {
      [field]: value
    });
    await query(`org/${organization}/user/${id}`, {}, ['users', id]);
  };

  return (
    <>
      <TitleContainer>
        <Capitalized variant="h5">{t('hours')}</Capitalized>
      </TitleContainer>
      <Line />
      <Gap />
      <FormRow>
        <FormLabel>{`${t('hoursPerDay')}:`}</FormLabel>
        <FormNumberInput
          initialValue={user?.hoursPerDay}
          onChange={(value: number) => updateUser('hoursPerDay', value)}
        />
      </FormRow>
      <FormRow>
        <FormLabel>{`${t('hoursPerWeek')}:`}</FormLabel>
        <FormNumberInput
          initialValue={user?.hoursPerWeek}
          onChange={(value: number) => updateUser('hoursPerWeek', value)}
        />
      </FormRow>
    </>
  );
}
