import React, {
  PropsWithChildren,
  ReactNode,
  useEffect,
  useState
} from 'react';
import { Box, Input, TextField, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { useDebounce } from 'framework';

export const FormRow = styled(Box)`
  margin: ${p => p.theme.spacing(1, 0)};
  display: flex;

  ${p => p.theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
  ${p => p.theme.breakpoints.up('md')} {
    flex-direction: row;
    align-items: center;
  }
`;

const FormLabelInner = styled(Typography)`
  ${p => p.theme.breakpoints.up('md')} {
    flex: 0 0 200px;
    width: 200px;
  }
  padding: ${p => p.theme.spacing(1)}px;
  display: inline-block;
  text-transform: capitalize;
`;

export const FormLabel = ({ children }: PropsWithChildren<{}>) => (
  <FormLabelInner variant="caption">{children}</FormLabelInner>
);

const FormTextInputBlock = styled(Box)`
  ${p => p.theme.breakpoints.up('md')} {
    flex: 0 0 288px;
    width: 288px;
  }

  display: inline-block;
`;

const FormGeneralInputInner = styled(Input)`
  width: 270px;
  padding: ${p => p.theme.spacing(1)}px;
`;

const FormTextInputInner = styled(TextField)`
  padding: ${p => p.theme.spacing(1)}px;
`;

export const FormFieldWrapper = styled(Box)`
  padding: ${p => p.theme.spacing(1, 1)};
  ${p => p.theme.breakpoints.up('md')} {
    flex: 0 0 288px;
    width: 288px;
  }
  display: inline-block;
`;

interface FormTextInputProps {
  initialValue: string | undefined;
  onChange?: (newValue: string) => unknown;
  validate?: (newValue: string) => boolean;
  rowsMin?: number;
  rowsMax?: number;
  rows?: number;
  multiline?: boolean;
  placeholder?: string;
  disabled?: boolean;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
}

const FormTextValueInner = styled(Typography)`
  ${p => p.theme.breakpoints.up('md')} {
    flex: 0 0 288px;
    width: 288px;
  }
  display: inline-block;
  padding: ${p => p.theme.spacing(1)}px;
`;

export const FormTextValue = ({ children }: PropsWithChildren<{}>) => {
  return <FormTextValueInner variant="body1">{children}</FormTextValueInner>;
};

export const FormTextInput = ({
  initialValue,
  onChange,
  validate,
  ...restProps
}: FormTextInputProps) => {
  const [value, setValue] = useState<string | undefined>();
  const [valid, setValid] = useState(true);

  const debouncedConfirm = useDebounce(onChange || (() => {}), 750);

  useEffect(() => {
    if (!value) {
      return;
    }
    if (validate && !validate(value)) {
      setValid(false);
      return;
    }
    setValid(true);

    debouncedConfirm(value);
  }, [value, setValid, validate, debouncedConfirm]);

  return (
    <FormTextInputBlock>
      <FormTextInputInner
        {...restProps}
        type="text"
        error={!valid}
        value={value || initialValue}
        fullWidth
        InputProps={{
          endAdornment: restProps.endAdornment,
          startAdornment: restProps.startAdornment
        }}
        onChange={e => e.target.value !== value && setValue(e.target.value)}
      />
    </FormTextInputBlock>
  );
};

interface FormNumberInputProps {
  initialValue: number | undefined;
  onChange?: (newValue: number) => unknown;
  validate?: (newValue: string) => boolean;
  min?: number;
  max?: number;
  placeholder?: string;
  disabled?: boolean;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
}

export const FormNumberInput = ({
  initialValue,
  onChange,
  validate,
  ...restProps
}: FormNumberInputProps) => {
  const [value, setValue] = useState<number | undefined>();
  const [valid, setValid] = useState(true);

  const debouncedConfirm = useDebounce(onChange || (() => {}), 750);

  useEffect(() => {
    if (value === undefined) {
      return;
    }
    if (validate && !validate(`${value}`)) {
      setValid(false);
      return;
    }

    if (Number.isNaN(value)) {
      setValid(false);
      return;
    }

    setValid(true);

    debouncedConfirm(value);
  }, [value, setValid, validate, debouncedConfirm]);

  return (
    <FormTextInputBlock>
      <FormGeneralInputInner
        {...restProps}
        type="number"
        error={!valid}
        value={value ?? initialValue}
        inputProps={{
          min: restProps.min,
          max: restProps.max
        }}
        onChange={e =>
          Number.parseFloat(e.target.value) !== value &&
          setValue(Number.parseFloat(e.target.value))
        }
      />
    </FormTextInputBlock>
  );
};
