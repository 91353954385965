import React, { useEffect } from 'react';
import { Box, Switch, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useSpot } from 'framework';
import {
  FormLabel,
  FormRow,
  Line,
  TitleContainer,
  Capitalized,
  Gap,
  FormNumberInput
} from 'components';

const InputPostText = styled(Typography)`
  padding: ${p => p.theme.spacing(0, 1)};
  white-space: nowrap;
`;

export function LeaveConfiguration({
  id,
  organization
}: {
  id: string;
  organization: string;
}) {
  const { t } = useTranslation();
  const { data, command, query } = useSpot();

  const user = data?.users?.[id];
  const org = data?.organizations?.[organization];

  const shouldLookupOrg = !data?.organizations?.[organization];

  useEffect(() => {
    (async () => {
      if (shouldLookupOrg) {
        await query(`org/${organization}`, {}, ['organizations', organization]);
      }
      await query(`org/${organization}/user/${id}`, {}, ['users', id]);
    })();
  }, [query, organization, id, shouldLookupOrg]);

  const updateUser = async (field: string, value: unknown) => {
    await command(`org/${organization}/user/${id}`, {
      [field]: value
    });
    await query(`org/${organization}/user/${id}`, {}, ['users', id]);
  };

  return (
    <>
      <TitleContainer>
        <Capitalized variant="h5">{t('leaveTypes')}</Capitalized>
      </TitleContainer>
      <FormRow>
        <Typography variant="body2">
          {t('leaveTypeStaffOverride', { firstName: user?.firstName ?? '' })}
        </Typography>
        <Gap size={4} />
        <Switch
          checked={user?.useCustomLeaveTypes}
          onChange={(event: React.ChangeEvent<{ checked: boolean }>) => {
            updateUser('useCustomLeaveTypes', event.target.checked);
          }}
        />
      </FormRow>
      <Line />

      {Object.entries(user?.leaveTypes ?? {})
        ?.filter(([type]) => !org?.disabledLeaveTypes.includes(type))
        .map(([type, hours]) => (
          <FormRow key={type}>
            <FormLabel>{`${t(type)}:`}</FormLabel>
            <Box display="flex">
              <FormNumberInput
                initialValue={hours}
                min={0}
                max={365}
                disabled={!user?.useCustomLeaveTypes}
                endAdornment={
                  <InputPostText variant="caption">
                    {t('perYear')}
                  </InputPostText>
                }
                onChange={(value: number) =>
                  updateUser('leaveTypes', {
                    ...(user?.leaveTypes ?? {}),
                    [type]: value
                  })
                }
              />
            </Box>
          </FormRow>
        ))}
    </>
  );
}
