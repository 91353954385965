import { useContext, useEffect, useState } from 'react';

import { AuthContext } from './auth-context';
import { baseUrl } from './base-url';
import { getOrCacheFile, hasCachedFile, getCachedFile } from './file-cache';

export const useFile = (filename: string, orgId: string, userId?: string) => {
  const cacheKey = `${userId ?? 'public'}/${filename}`;

  const { getToken } = useContext(AuthContext);
  const [fileUrl, setFileUrl] = useState(
    hasCachedFile(cacheKey) ? getCachedFile(cacheKey) : ''
  );
  const [originalUrl, setOriginalUrl] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!filename) {
      return;
    }
    setLoading(true);

    if (hasCachedFile(cacheKey)) {
      setFileUrl(getCachedFile(cacheKey));
      setLoading(false);
      return;
    }

    const fetchFileUrl = async () => {
      const url = userId
        ? `${baseUrl}/org/${orgId}/user/${userId}/getUserFileUrl/${filename}`
        : `${baseUrl}/org/${orgId}/getPublicFileUrl/${filename}`;
      // Fetch download url
      const downloadResponse = await fetch(`${url}`, {
        headers: {
          authorization: getToken().getJwtToken()
        },
        method: 'get'
      });

      const { url: accessibleUrl } = await downloadResponse.json();
      setOriginalUrl(accessibleUrl);
      const cacheUrl = await getOrCacheFile(cacheKey, accessibleUrl);
      setFileUrl(cacheUrl);
      setLoading(false);
    };
    fetchFileUrl();
  }, [filename, setFileUrl, fileUrl.length, cacheKey, getToken, userId, orgId]);

  return { url: fileUrl, loading, originalUrl };
};
