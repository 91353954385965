import React, { useContext, useState } from 'react';
import { useParams, useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Button, Hidden, Fab } from '@material-ui/core';
import styled from 'styled-components';
import { LiveHelpRounded as HelpIcon } from '@material-ui/icons';

import {
  PageDescriptor,
  AuthContext,
  useStoredState,
  openInNewTab
} from 'framework';
import { Spacer } from 'components';

import { Page } from './page';
import { Header } from './header';

const CalloutButton = styled(Button)`
  border-radius: 20px;
  padding: ${p => p.theme.spacing(1, 2)}
  background-color: #ffffff;
  margin-right: ${p => p.theme.spacing(2)}px;
  white-space: nowrap;
  min-width: 140px;
`;

const CookieBanner = styled(Box)`
  display: flex;
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  background: ${p => p.theme.palette.primary.main};
  align-items: center;
  color: ${p => p.theme.palette.primary.contrastText};
`;

const RootContainer = styled(Box)`
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const HelpButtonContainer = styled(Box)`
  position: fixed;
  bottom: ${p => p.theme.spacing(4)}px;
  right: ${p => p.theme.spacing(4)}px;
`;

export function Template({ page }: { page: PageDescriptor }) {
  const { organization } = useParams<{ organization: string }>();
  const history = useHistory();
  const {
    isInitialized,
    init,
    isSignedIn,
    requestSignin,
    getGroups
  } = useContext(AuthContext);
  const { t } = useTranslation();
  const [authState, setAuthState] = useState<
    'uninitialized' | 'pending' | 'initialized'
  >('uninitialized');

  const [acceptedCookedBanner, setAcceptedCookieBanner] = useStoredState<
    boolean
  >('spothr-cookiebanner', false);

  document.title = page.title ? `Spot HR - ${t(page.title)}` : 'Spot HR';

  if (page.authorized && !isInitialized()) {
    const initAuth = async () => {
      setAuthState('pending');
      await init(organization);
      setAuthState('initialized');
    };

    if (authState === 'uninitialized') {
      initAuth();
    }

    return <>{t('loading')}</>;
  }

  if (page.authorized && !isSignedIn()) {
    requestSignin();
    return <>{t('loggingIn')}</>;
  }

  const userGroups = isInitialized() ? getGroups() : [];
  const inGroup = page.groups?.length
    ? page.groups?.some(group => userGroups.includes(group))
    : true;

  if (!inGroup) {
    history.replace(`/${organization}`);
    return <></>;
  }

  return (
    <RootContainer>
      {!page.ignoreContainer && <Header />}
      <Page
        key={page.path}
        ignoreContainer={page.ignoreContainer}
        noHeader={page.noHeader}
      >
        <page.screen />
      </Page>
      {page.authorized && isSignedIn() && (
        <HelpButtonContainer>
          <Fab
            color="primary"
            onClick={() => openInNewTab('https://blog.spothr.net/contact/')}
          >
            <HelpIcon />
          </Fab>
        </HelpButtonContainer>
      )}
      {!acceptedCookedBanner && (
        <CookieBanner>
          <Box padding={2}>
            <Hidden mdDown>
              <Typography variant="h4">{t('cookieTitle')}</Typography>
            </Hidden>
            <Hidden smDown>
              <Typography variant="body1">{t('cookieDesc')}</Typography>
            </Hidden>
            <Hidden mdUp>
              <Typography variant="caption">{t('cookieDesc')}</Typography>
            </Hidden>
          </Box>
          <Spacer />
          <CalloutButton
            variant="contained"
            onClick={() => setAcceptedCookieBanner(true)}
          >
            {t('cookieButton')}
          </CalloutButton>
        </CookieBanner>
      )}
    </RootContainer>
  );
}
