import { Box } from '@material-ui/core';
import styled from 'styled-components';

export const Spacer = styled(Box)`
  flex: 1 1 auto;
`;

export const Gap = styled(Box)<{ size?: number }>`
  margin-top: ${p => p.theme.spacing(p.size ?? 2)}px;
  margin-left: ${p => p.theme.spacing(p.size ?? 2)}px;
`;
