import { Box } from '@material-ui/core';
import React, {
  MutableRefObject,
  ReactNode
  // useCallback,
  // useEffect,
  // useRef,
  // useState
} from 'react';
import styled from 'styled-components';

export const FadeInBox = styled(Box)<{
  ref?: MutableRefObject<HTMLElement | undefined>;
  opacity: number;
}>`
  display: inherit;
  flex: inherit;
  position: inherit;
  opacity: ${p => p.opacity};
`;

export function FadeComponent({ children }: { children: ReactNode }) {
  // // const element = useRef<HTMLElement>();
  // // const [opacity, setOpacity] = useState(0);

  // // const updateVisible = useCallback(() => {
  // //   if (!element.current) {
  // //     return;
  // //   }

  // //   const rect = element.current.getBoundingClientRect();
  // //   const docHeight =
  // //     window.innerHeight || document.documentElement.clientHeight;
  // //   const currentVisible =
  // //     (rect.top >= 0 && rect.top <= docHeight) ||
  // //     (rect.bottom >= 0 && rect.bottom <= docHeight);

  // //   let newOpacity = 0;
  // //   if (!currentVisible) {
  // //     newOpacity = 0;
  // //   }

  // //   const top = rect.height / 2;
  // //   const bottom = docHeight - rect.height / 2;
  // //   const center = rect.top + (rect.bottom - rect.top) / 2;

  // //   console.log(center, top, bottom, rect.height, docHeight);

  // //   if (center > top && center < bottom) {
  // //     newOpacity = 1;
  // //   } else if (center < top) {
  // //     newOpacity = 1 - (top - center) / top;
  // //   } else if (center > bottom) {
  // //     newOpacity = 1 - (center - bottom) / top;
  // //   } else {
  // //     newOpacity = NaN;
  // //   }
  // //   setOpacity(newOpacity);
  // // }, [setOpacity, element]);

  // // useEffect(() => {
  // //   updateVisible();
  // // }, [updateVisible]);

  // // useEffect(() => {
  // //   document.addEventListener('scroll', updateVisible);
  // //   return () => {
  // //     document.removeEventListener('scroll', updateVisible);
  // //   };
  // // }, [updateVisible]);

  // return (
  //   <FadeInBox ref={element} opacity={opacity}>
  //     <>{children}</>
  //   </FadeInBox>
  // );

  return (
    <Box>
      <>{children}</>
    </Box>
  );
}
