import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  TextField,
  Typography,
  Checkbox,
  IconButton,
  Container
} from '@material-ui/core';
import { loadStripe } from '@stripe/stripe-js';
import {
  CheckCircleRounded as ValidIcon,
  CancelRounded as InvalidIcon,
  CloseRounded as CloseIcon
} from '@material-ui/icons';
import styled from 'styled-components';

import { baseUrl, useDebounce, validateEmail } from 'framework';
import { Line, Spacer, TitleContainer } from 'components';
import { TermsOfService, PrivacyPolicy } from './shared';

const CentralPanel = styled(Card)`
  padding: ${p => p.theme.spacing(2, 2)};
  max-width: 640px;
  margin: auto;
`;

const TosContainer = styled(Container)`
  padding: ${p => p.theme.spacing(4)}px;
  background-color: ${p => p.theme.palette.background.paper};
`;

const Row = styled(Box)`
  display: flex;
  margin: ${p => p.theme.spacing(2, 2)};
  flex-direction: row;
  align-items: center;
`;

const CheckoutButton = styled(Button)`
  width: 160px;
`;

const TosPrivacyText = styled(Typography)`
  cursor: pointer;
  user-select: none;
`;

const TextLink = styled(Typography)`
  margin-left: 0.33em;
  margin-right: 0.33em;
  text-decoration: underline;
  color: ${p => p.theme.palette.primary.main};
  cursor: pointer;
  user-select: none;
`;

const stripePromise = loadStripe(
  process.env.NODE_ENV === 'development'
    ? 'pk_test_51I6GmHAtxDRDfBa8xGT7bRGlM6wp91elOVDbRlJ6Zg2eJLoWUVWNb8Z0uHDUAf85Cn36PUuy8dhxVcN1ycA1JHAY00417VEnM9'
    : 'pk_live_51I6GmHAtxDRDfBa8RBXzuvxjjgGFqX9bkdL65YohxTfTMoJ7CRWXl1UAluOiN2k7lhkHeHsttYD0BYrl8MIkUztV00lVXgT9FA'
);

interface State {
  orgId?: string;
  email?: string;
}

export function Checkout() {
  const { t } = useTranslation();
  const { product } = useParams<{ product: string }>();

  const [state, setState] = useState<State>({});
  const [valid, setValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [acceptedTos, setAcceptedTos] = useState(false);
  const [tosVisible, setTosVisible] = useState(false);
  const [privacyVisible, setPrivacyVisible] = useState(false);

  const checkOrg = async (orgId: string) => {
    // const { orgId } = state;
    if (!orgId || orgId.length <= 3) {
      setValid(false);
      setLoading(false);
      return;
    }

    setLoading(true);
    const response = await fetch(`${baseUrl}/checkOrg/${orgId}`);
    if (response.status === 404) {
      setValid(true);
    } else {
      setValid(false);
    }
    setLoading(false);
  };

  const debouncedCheckOrg = useDebounce(checkOrg, 500);

  const checkout = async (e: React.MouseEvent) => {
    e.preventDefault();
    const stripe = await stripePromise;
    const { email, orgId } = state;
    if (!email || !orgId) {
      return;
    }

    const response = await fetch(`${baseUrl}/payment/new`, {
      method: 'post',
      body: JSON.stringify({
        orgId,
        email,
        product
      }),
      headers: {
        'content-type': 'application/json'
      }
    });
    const { session } = await response.json();

    await stripe?.redirectToCheckout({
      sessionId: session
    });
  };

  const renderIcon = (v: boolean) =>
    v ? (
      <ValidIcon style={{ color: '#55f055' }} />
    ) : (
      <InvalidIcon style={{ color: '#f05555' }} />
    );

  if (privacyVisible) {
    return (
      <Box mt={4}>
        <Row>
          <Spacer />
          <IconButton onClick={() => setPrivacyVisible(false)}>
            <CloseIcon />
          </IconButton>
        </Row>
        <TosContainer>
          <PrivacyPolicy />
        </TosContainer>
      </Box>
    );
  }
  if (tosVisible) {
    return (
      <Box mt={4}>
        <Row>
          <Spacer />
          <IconButton onClick={() => setTosVisible(false)}>
            <CloseIcon />
          </IconButton>
        </Row>
        <TosContainer>
          <TermsOfService />
        </TosContainer>
      </Box>
    );
  }

  return (
    <Box mt={4}>
      <form>
        <CentralPanel>
          <TitleContainer>
            <Typography variant="h4" align="center">
              {t('createOrg')}
            </Typography>
          </TitleContainer>
          <Row />
          <Line />
          <Row />
          <Row>
            <Typography variant="body2">{t('orgEmailDesc')}</Typography>
          </Row>
          <Row>
            <TextField
              label={t('orgEmail')}
              value={state.email}
              fullWidth
              variant="outlined"
              size="medium"
              InputProps={{
                endAdornment: renderIcon(validateEmail(state.email))
              }}
              onChange={(event: React.ChangeEvent<{ value: string }>) => {
                setState({ ...state, email: event.target.value });
              }}
            />
          </Row>
          <Row />
          <Row>
            <Typography variant="body2">{t('orgIdDesc')}</Typography>
          </Row>
          <Row>
            <TextField
              label={t('orgId')}
              value={state.orgId}
              fullWidth
              variant="outlined"
              size="medium"
              InputProps={{
                endAdornment: loading ? (
                  <CircularProgress size={24} />
                ) : (
                  renderIcon(valid)
                )
              }}
              onChange={(event: React.ChangeEvent<{ value: string }>) => {
                const newOrgId = event.target.value.toLocaleLowerCase();
                setState({
                  ...state,
                  orgId: newOrgId
                });
                debouncedCheckOrg(newOrgId);
              }}
            />
          </Row>
          <Row />
          <Row>
            <Checkbox
              checked={acceptedTos}
              onChange={(e: React.ChangeEvent<{ checked: boolean }>) => {
                setAcceptedTos(e.target.checked);
              }}
            />
            <TosPrivacyText
              onClick={() => setAcceptedTos(!acceptedTos)}
              variant="body2"
            >
              {t('agreeToTos1')}
            </TosPrivacyText>
            <TextLink onClick={() => setTosVisible(true)} variant="body2">
              {t('agreeToTos2')}
            </TextLink>
            <TosPrivacyText
              onClick={() => setAcceptedTos(!acceptedTos)}
              variant="body2"
            >
              {t('agreeToTos3')}
            </TosPrivacyText>
            <TextLink onClick={() => setPrivacyVisible(true)} variant="body2">
              {t('agreeToTos4')}
            </TextLink>
          </Row>
          <Row>
            <Spacer />
            <CheckoutButton
              size="large"
              variant="contained"
              color="secondary"
              disabled={!valid || !validateEmail(state.email) || !acceptedTos}
              onClick={checkout}
              type="submit"
            >
              {t('checkout')}
            </CheckoutButton>
          </Row>
        </CentralPanel>
      </form>
    </Box>
  );
}
