import React, { useState } from 'react';
import { useHistory } from 'react-router';
import {
  Box,
  Typography,
  Button,
  TextField,
  CircularProgress
} from '@material-ui/core';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  ArrowForwardRounded as GoIcon,
  CheckCircleRounded as ValidIcon,
  CancelRounded as InvalidIcon
} from '@material-ui/icons';

import { baseUrl, useDebounce } from 'framework';
import { Line, TitleContainer } from '../components';

const Container = styled(Box)`
  display: flex;
  flex-direction: row;
  max-width: 640px;
  margin: ${p => p.theme.spacing(4)}px auto;
`;

export function Signin() {
  const [orgId, setOrgId] = useState('');
  const history = useHistory();
  const { t } = useTranslation();

  const [valid, setValid] = useState(false);
  const [loading, setLoading] = useState(false);

  const checkOrg = async (o: string) => {
    // const { orgId } = state;
    if (!o || o.length <= 3) {
      setValid(false);
      setLoading(false);
      return;
    }

    setLoading(true);
    const response = await fetch(`${baseUrl}/checkOrg/${o}`);
    if (response.status === 404 || response.status === 403) {
      setValid(false);
    } else {
      setValid(true);
    }
    setLoading(false);
  };

  const debouncedCheckOrg = useDebounce(checkOrg, 500);

  const navigate = (e: React.MouseEvent) => {
    e.preventDefault();
    history.push(`/${orgId}`);
  };

  const renderIcon = (v: boolean) =>
    v ? (
      <ValidIcon style={{ color: '#55f055' }} />
    ) : (
      <InvalidIcon style={{ color: '#f05555' }} />
    );

  return (
    <>
      <TitleContainer>
        <Typography variant="h5">{t('signin')}</Typography>
      </TitleContainer>
      <Line />
      <Container mb={4}>
        <Typography variant="body2">{t('signinOrgIdHint')}</Typography>
      </Container>
      <form>
        <Container>
          <TextField
            label={t('orgId')}
            value={orgId}
            fullWidth
            variant="outlined"
            size="medium"
            onChange={(event: React.ChangeEvent<{ value: string }>) => {
              const newOrgId = event.target.value.toLocaleLowerCase();
              setOrgId(newOrgId);
              debouncedCheckOrg(newOrgId);
            }}
            InputProps={{
              endAdornment: loading ? (
                <CircularProgress size={24} />
              ) : (
                renderIcon(valid)
              )
            }}
          />
          <Box mr={2} />
          <Button
            disabled={loading || !valid}
            variant="contained"
            color="secondary"
            onClick={navigate}
            endIcon={<GoIcon />}
            type="submit"
          >
            {t('go')}
          </Button>
        </Container>
      </form>
    </>
  );
}
