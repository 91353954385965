import React, { useContext, useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import { Avatar, IconButton } from '@material-ui/core';

import { AuthContext, useFile, useSpot } from 'framework';

export const SignedInUserMenu = () => {
  const { organization } = useParams<{ organization: string }>();
  const { getCurrentUser } = useContext(AuthContext);
  const history = useHistory();

  const id = getCurrentUser();

  const { data, query, command } = useSpot();
  const user = data?.users ? data?.users?.[id] : null;
  const avatar = user?.avatar;

  const gotoUser = () => {
    history.push(`/${organization}/user`);
  };

  const { url } = useFile(avatar || '', organization, id);

  useEffect(() => {
    query(`org/${organization}/user/${id}`, {}, ['users', id]);
  }, [organization, id, query, command]);

  useEffect(() => {
    if (data?.users?.[id] && !data?.users?.[id].verified) {
      command(`org/${organization}/user/${id}/verify`);
    }
  }, [organization, data, id, command]);

  return (
    <IconButton onClick={gotoUser}>
      <Avatar src={url} />
    </IconButton>
  );
};
