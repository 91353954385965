import React from 'react';
import { Typography } from '@material-ui/core';

import { CustomDialog } from './custom-dialog';

interface Props {
  title: string;
  text: string;
  dialogOpen: boolean;
  onSubmit?: () => Promise<void>;
  onClose?: () => unknown;
  acceptLabel?: string;
  rejectLabel?: string;
}

export function ConfirmDialog(props: Props) {
  const { text, onSubmit } = props;
  const customSubmit = async () => onSubmit && onSubmit();

  const renderControl = (setValue: (val?: string) => void, value?: string) => {
    return <Typography>{value}</Typography>;
  };

  return CustomDialog({
    ...props,
    initialValue: text,
    onSubmit: customSubmit,
    renderControl
  });
}
