import { useEffect, useState } from 'react';
import { baseUrl } from './base-url';

interface FeatureFlags {
  batchImport: boolean;
}

let globalFlagStore: FeatureFlags | undefined;

const getGlobalFeatureFlags = async () => {
  if (!globalFlagStore) {
    const response = await fetch(`${baseUrl}/features`);
    globalFlagStore = (await response.json()) as FeatureFlags;
  }

  return globalFlagStore;
};

export const useFeatureFlags = () => {
  const [global, setGlobal] = useState<FeatureFlags | undefined>();

  useEffect(() => {
    (async () => {
      const res = await getGlobalFeatureFlags();
      setGlobal(res);
    })();
  }, [setGlobal]);

  return { global };
};
