import React from 'react';

import { useSpot } from 'framework';
import { Tabs } from 'components';

import { FileBrowser } from './file-browser';
import { ProfileOverview } from './profile-overview';
import { LeaveRemaining } from './leave-remaining';
import { LeaveRequests } from './leave-requests';
import { LeaveConfiguration } from './leave-configuration';
import { HourConfiguration } from './hour-configuration';

type Tab =
  | 'profile'
  | 'leaveRemaining'
  | 'leaveRequests'
  | 'files'
  | 'hourConfig'
  | 'leaveConfig';

interface Props {
  id: string;
  organization: string;
  tab?: string;
  onTabChange?: (tab: string) => void;
  disabledTabs?: Tab[];
}

export const UserInfo = ({
  id,
  organization,
  tab,
  onTabChange,
  disabledTabs
}: Props) => {
  const { loading } = useSpot();

  const tabs: { [k: string]: JSX.Element } = {
    profile: <ProfileOverview id={id} organization={organization} />,
    leaveRemaining: <LeaveRemaining id={id} organization={organization} />,
    leaveRequests: <LeaveRequests id={id} organization={organization} />,
    files: <FileBrowser organization={organization} id={id} />,
    leaveConfig: <LeaveConfiguration organization={organization} id={id} />,
    hourConfig: <HourConfiguration organization={organization} id={id} />
  };

  // eslint-disable-next-line no-unused-expressions
  disabledTabs?.forEach(tb => delete tabs[tb]);

  return (
    <>
      <Tabs
        tabs={tabs}
        selected={tab}
        onTabChange={onTabChange}
        loading={loading}
      />
    </>
  );
};
