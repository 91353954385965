import React, { useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { Box, Typography, Button } from '@material-ui/core';
import styled from 'styled-components';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  WorkOffRounded as GeneralLeaveIcon,
  CakeRounded as BirthdayIcon,
  BeachAccessRounded as PublicHolidayIcon
} from '@material-ui/icons';

// eslint-disable-next-line import/no-cycle
import { pages } from 'pages';
import { Line, Row, Table, TitleContainer } from 'components';
import { AuthContext, useSpot } from 'framework';
import { EventType } from 'domain-types';

const Events = styled(Box)`
  flex: 1 1 480px;
  border-right: 1px solid #f0f0f0;
  margin-right: ${p => p.theme.spacing(2)}px;
`;

const Container = styled(Box)`
  flex: 1 1 480px;
  display: flex;
  flex-direction: row;
  max-height: 640px;
  overflow: auto;
`;

const StyledButton = styled(Button)`
  margin: ${p => p.theme.spacing(2)}px;
  ${p => p.theme.breakpoints.down('sm')} {
    width: 130px;
    height: 130px;
  }
  ${p => p.theme.breakpoints.up('md')} {
    width: 192px;
    height: 192px;
  }

  padding: ${p => p.theme.spacing(2)}px;

  .MuiButton-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const ButtonTitle = styled(Typography)`
  ${p => p.theme.breakpoints.down('sm')} {
    font-size: 0.9em;
  }
`;

const Spacer = styled(Box)`
  margin-top: ${p => p.theme.spacing(2)}px;
`;

export function Dashboard() {
  const history = useHistory();
  const { t } = useTranslation();
  const { organization } = useParams<{ organization: string }>();
  const { isInitialized, isSignedIn, getGroups } = useContext(AuthContext);

  const { data, query } = useSpot();

  useEffect(() => {
    (async () => {
      await query(`org/${organization}`, {}, ['organizations', organization]);
      await query(`org/${organization}/overview`, {}, [
        'organizations',
        organization,
        'overview'
      ]);
    })();
  }, [organization, query]);

  const { name, overview } = data?.organizations?.[organization] ?? {};

  const getTypeIcon = (type: EventType) => {
    switch (type) {
      case 'birthday':
        return <BirthdayIcon />;
      case 'leave':
        return <GeneralLeaveIcon />;
      case 'public-holiday':
        return <PublicHolidayIcon />;
      default:
        return <GeneralLeaveIcon />;
    }
  };

  return (
    <>
      <TitleContainer>
        <Typography variant="h5">{name}</Typography>
      </TitleContainer>
      <Line />
      <Row>
        <Container>
          <Box>
            {Object.values(pages)
              .filter(innerPage => !innerPage.hidden)
              .filter(innerPage => {
                if (!innerPage.authorized) {
                  return true;
                }

                if (!isInitialized() || !isSignedIn()) {
                  return false;
                }

                const userGroups = isInitialized() ? getGroups() : [];
                return innerPage?.groups?.length
                  ? innerPage.groups?.some(group => userGroups.includes(group))
                  : true;
              })
              .map(page => {
                const Icon = page.icon;
                return (
                  <StyledButton
                    key={page.title}
                    variant="outlined"
                    onClick={() =>
                      history.push(
                        page.path
                          .replaceAll(':organization', organization)
                          .replaceAll(':userId', '')
                          .replaceAll(':tab', '')
                      )
                    }
                  >
                    <Icon fontSize="large" />
                    <Spacer />
                    <ButtonTitle variant="subtitle1" align="center">
                      {page.title ? t(page.title) : name}
                    </ButtonTitle>
                  </StyledButton>
                );
              })}
          </Box>
        </Container>
        <Events>
          <Table
            maxHeight="640px"
            rows={
              overview?.events.map(event => ({
                id: `${event.name}-${event.date}`,
                cells: [
                  getTypeIcon(event.type),
                  event.name,
                  `${moment(event.date).format('D MMMM')}${
                    event.end && event.end !== event.date
                      ? ` - ${moment(event.end).format('D MMMM')}`
                      : ''
                  }`
                ]
              })) ?? []
            }
          />
        </Events>
      </Row>
    </>
  );
}
