import React, { useEffect, useState } from 'react';
import { TextField, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@material-ui/pickers';
import { CalendarTodayRounded as CalenderIcon } from '@material-ui/icons';
import moment from 'moment';

import { Gap } from 'components/spacer';
import { CustomDialog } from './custom-dialog';

export interface PublicHolidayResult {
  name?: string;
  date?: string;
}

interface Props {
  title: string;
  text?: string;
  initialValue?: PublicHolidayResult;
  dialogOpen: boolean;
  onSubmit?: (user: PublicHolidayResult) => Promise<void>;
  onClose?: () => unknown;
}

export const EditPublicHolidayDialog = (props: Props) => {
  const { t } = useTranslation();
  const [validated, setValidated] = useState(false);
  useEffect(() => {
    setValidated(false);
  }, [props.dialogOpen]);

  const onValidate = (value?: PublicHolidayResult) => {
    setValidated(true);

    if (!value?.name || !value.date) return false;

    return true;
  };

  const renderControl = (
    setValue: (val?: PublicHolidayResult) => void,
    value?: PublicHolidayResult
  ) => {
    const handleDateChange = (date: string) => {
      setValue({ ...value, date });
    };

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue({ ...value, name: e.target.value });
    };

    const { text } = props;

    return (
      <>
        {!!text && <Typography>{text}</Typography>}
        <Gap />
        <TextField
          autoFocus
          required
          error={validated && !value?.name}
          value={value?.name}
          onChange={handleNameChange}
          placeholder={t('enterName')}
          margin="dense"
          label={t('name')}
          type="text"
          fullWidth
        />
        <DatePicker
          value={value?.date ? moment(value?.date, 'DD-MM') : null}
          margin="dense"
          format="D MMMM"
          onChange={v => handleDateChange(v?.format('DD-MM') ?? '')}
          fullWidth
          showTodayButton
          views={['month', 'date']}
          placeholder={t('enterDate')}
          label={value?.date ? ' ' : t('date')}
          error={validated && !value?.date}
          InputProps={{
            endAdornment: <CalenderIcon color="action" fontSize="small" />
          }}
        />
      </>
    );
  };

  return CustomDialog({ ...props, renderControl, onValidate });
};
