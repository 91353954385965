import React, { ReactNode } from 'react';
import {
  Box,
  Container,
  Hidden,
  IconButton,
  Typography,
  Button,
  SvgIcon
} from '@material-ui/core';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { openInNewTab } from 'framework';
import { Gap, Spacer } from 'components';
import beachImg from 'images/beach.jpg';

const ScrollContainer = styled(Box)`
  overflow: auto;
  position: relative;
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 64px);
`;

const StyledContent = styled(Container)`
  padding: ${p => p.theme.spacing(3, 3)};
  background: ${p => p.theme.palette.background.paper};
  border-radius: 4px;
  box-shadow: ${p => p.theme.shadows[1]};
  flex: 0;
`;

const Footer = styled(Typography)`
  width: 100%;
  padding: ${p => p.theme.spacing(2, 0)};
  background-color: ${p => p.theme.palette.background.default};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const BgHeader = styled(Box)`
  display: block;
  width: 100%;
  height: 300px;
  background-size: cover;
  background-image: url("${beachImg}");
  overflow: hidden;
  background-position: center;
  position: absolute;
  z-index: -1;
`;

const FooterLink = styled(Button)`
  text-transform: none;
`;

interface Props {
  children: ReactNode;
  ignoreContainer?: boolean;
  noHeader?: boolean;
}

export function Page({ children, ignoreContainer, noHeader }: Props) {
  const { t } = useTranslation();
  const year = new Date().getFullYear();
  const MainContainer = ignoreContainer ? Box : ScrollContainer;
  const PageContainer = ignoreContainer ? Box : StyledContent;
  return (
    <MainContainer>
      {!ignoreContainer && (
        <Hidden mdDown>
          {!noHeader && <BgHeader />}
          <Gap size={4} />
        </Hidden>
      )}
      <PageContainer>{children ?? <></>}</PageContainer>
      <Gap size={4} />
      <Spacer />
      <Footer variant="caption" align="center">
        <Typography variant="caption">{`© ${year} GoemansTech `}</Typography>
        <Gap size={2} />
        <IconButton
          onClick={() => openInNewTab('https://twitter.com/spothrnet')}
        >
          <SvgIcon>
            ...
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-twitter"
            >
              <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z" />
            </svg>
          </SvgIcon>
        </IconButton>
        <FooterLink
          size="large"
          onClick={() => openInNewTab('https://blog.spothr.net')}
          variant="text"
        >
          <Typography variant="caption">{t('blog')}</Typography>
        </FooterLink>
      </Footer>
    </MainContainer>
  );
}
