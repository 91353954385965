import { createMuiTheme } from '@material-ui/core/styles';

const shadow = (elevation: number) =>
  `0px ${elevation}px 5px 0px rgba(0,0,0,0.05)`;

export const theme = createMuiTheme({
  palette: {
    // https://www.canva.com/colors/color-meanings/blue-grotto/
    primary: {
      light: '#68BBE3',
      main: '#3097e8',
      dark: '#003060',
      contrastText: '#f7f7f7'
    },
    secondary: {
      main: '#F652A0'
    },
    background: {
      default: '#f7f7f7',
      paper: '#ffffff'
    }
  },
  typography: {
    fontFamily: ['Lato', 'sans-serif'].join(','),
    h3: {
      fontStyle: 'italic'
    },
    h4: {
      fontStyle: 'italic'
    },
    h5: {
      fontStyle: 'italic'
    }
  },
  shadows: [
    'none',
    shadow(1),
    shadow(2),
    shadow(3),
    shadow(4),
    shadow(5),
    shadow(6),
    shadow(7),
    shadow(8),
    shadow(9),
    shadow(10),
    shadow(11),
    shadow(12),
    shadow(13),
    shadow(14),
    shadow(15),
    shadow(16),
    shadow(17),
    shadow(18),
    shadow(19),
    shadow(20),
    shadow(21),
    shadow(22),
    shadow(23),
    shadow(24)
  ]
});
