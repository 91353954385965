export const fileCache: { [k: string]: string } = {};

const cacheFile = async (key: string, url: string) => {
  const response = await fetch(url);
  const blob = await response.blob();
  const localUrl = URL.createObjectURL(blob);
  fileCache[key] = localUrl;
};

export const hasCachedFile = (key: string) => !!fileCache[key];
export const getCachedFile = (key: string) => fileCache[key];

export const getOrCacheFile = async (key: string, url: string) => {
  if (!fileCache[key]) {
    await cacheFile(key, url);
  }
  return fileCache[key];
};
