import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, Typography } from '@material-ui/core';

import { Gap } from 'components/spacer';
import { CustomDialog } from './custom-dialog';

interface Props {
  title: string;
  text: string;
  validationWord: string;
  initialValue?: string;
  dialogOpen: boolean;
  onSubmit?: (name: string) => Promise<void>;
  onClose?: () => unknown;
}

export const PermanentlyDeleteDialog = (props: Props) => {
  const { t } = useTranslation();

  const { text, validationWord } = props;

  const canSubmit = (value?: string) => {
    if (!value || value !== validationWord) return false;
    return true;
  };

  const renderControl = (setValue: (val?: string) => void, value?: string) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
    };

    return (
      <>
        {!!text && <Typography>{text}</Typography>}
        <Gap />
        <Typography>
          {t('validationSubtitle', {
            validationWord
          })}
        </Typography>
        <Gap />
        <TextField
          autoFocus
          required
          error={!value || !canSubmit(value)}
          value={value}
          onChange={handleChange}
          placeholder={t('enterValidationWord')}
          margin="dense"
          label={t('validation')}
          type="text"
          fullWidth
        />
      </>
    );
  };

  return CustomDialog({
    ...props,
    renderControl,
    canSubmit
  });
};
