import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Tabs as MaterialTabs,
  Tab as MaterialTab,
  Box
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { HorizontalLoadingBar } from './horizontal-loading-bar';

const StyledTabs = styled(MaterialTabs)`
  margin-top: ${p => p.theme.spacing(3)}px;
  .MuiTabs-flexContainer {
    border-bottom: 1px solid ${p => p.theme.palette.grey['500']};
  }
  .MuiTabs-fixed {
    margin-bottom: ${p => p.theme.spacing(3)}px;
  }
`;

const LoadingContainer = styled(Box)`
  position: relative;
  top: 76px;
`;

export interface TabParams {
  tab?: string;
}

interface Props {
  selected?: string;
  tabs: { [k: string]: JSX.Element };
  onTabChange?: (tab: string) => unknown;
  loading?: boolean;
}

function Tabs({ selected, tabs, onTabChange, loading }: Props) {
  if (Object.keys(tabs).length === 0) {
    throw new Error('Must specify more than 0 tabs');
  }
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState<string>(
    selected || Object.keys(tabs)[0]
  );

  const handleTabChange = (event: unknown, selectedTab: string) => {
    setActiveTab(selectedTab);
    onTabChange && onTabChange(selectedTab);
  };

  return (
    <>
      <LoadingContainer>
        <HorizontalLoadingBar loading={loading ?? false} />
      </LoadingContainer>
      <StyledTabs
        value={activeTab}
        onChange={handleTabChange}
        indicatorColor="primary"
        variant="scrollable"
        textColor="primary"
      >
        {Object.keys(tabs).map(tabName => (
          <MaterialTab
            key={tabName}
            label={t(tabName) ?? tabName.split(/(?=[A-Z])/).join(' ')}
            value={tabName}
          />
        ))}
      </StyledTabs>
      {tabs[activeTab]}
    </>
  );
}

export { Tabs };
