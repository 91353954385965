import React, { useState, ReactChild, useEffect } from 'react';
import {
  Dialog as MaterialDialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Box
} from '@material-ui/core';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Capitalized } from 'components/styled';
import { Gap, Spacer } from 'components/spacer';
import { Notification } from '../notification';

const Line = styled(Box)`
  margin-bottom: 1px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
`;

const StyledDialog = styled(MaterialDialog)`
  .MuiDialog-paperScrollPaper {
    ${p => p.theme.breakpoints.down('sm')} {
      margin: 0;
      height: 100%;
      max-height: 100%;
      border-radius: 0;
    }

    position: relative;
  }
`;

const DialogForm = styled.form`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
`;

interface Props<T> {
  title: string;
  initialValue?: T;
  acceptLabel?: string;
  rejectLabel?: string;
  dialogOpen: boolean;
  renderControl: (setValue: (value?: T) => void, value?: T) => JSX.Element;
  onSubmit?: (name: T) => Promise<void>;
  onClose?: () => unknown;
  canSubmit?: (value?: T) => boolean;
  onValidate?: (value?: T) => boolean;
}

export function CustomDialog<T>({
  title,
  initialValue,
  acceptLabel,
  rejectLabel,
  dialogOpen,
  renderControl,
  onSubmit,
  onClose,
  canSubmit,
  onValidate
}: Props<T>) {
  const [value, setValue] = useState(initialValue || undefined);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const [notification, setNotification] = useState<ReactChild>();
  const handleSnackClose = () => setNotification(undefined);

  const handleClose = () => {
    if (loading) return;
    onClose && onClose();
  };

  useEffect(() => {
    setValue(initialValue || undefined);
  }, [initialValue, dialogOpen]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (value !== null && value !== undefined && !loading) {
      setLoading(true);

      try {
        if (onValidate && !onValidate(value)) {
          return;
        }

        onSubmit && (await onSubmit(value));
        setNotification(
          <Notification onClose={handleSnackClose} severity="success" />
        );
        setValue(undefined);
        onClose && onClose();
      } catch (error) {
        let errorMessage = t('error');
        if (Array.isArray(error)) {
          errorMessage = error
            .map(err => {
              try {
                return JSON.parse(err.body).error;
              } catch (e) {
                return error.statusText ?? t('error');
              }
            })
            .join('. ');
        }

        setNotification(
          <Notification
            onClose={handleSnackClose}
            severity="error"
            message={`${errorMessage}`}
          />
        );

        // Reset the value
        setValue(value);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <StyledDialog open={dialogOpen} onClose={handleClose}>
        <DialogForm noValidate onSubmit={handleSubmit}>
          <DialogTitle>
            <Capitalized variant="h5">{title}</Capitalized>
          </DialogTitle>
          <Line />
          <DialogContent>
            <Gap />
            {!!renderControl && renderControl(setValue, value)}
            <Gap />
          </DialogContent>
          <Spacer />
          <DialogActions>
            <Button onClick={handleClose} color="primary" disabled={loading}>
              <Capitalized>{rejectLabel ?? t('cancel')}</Capitalized>
            </Button>
            <Button
              color="primary"
              type="submit"
              disabled={loading || (canSubmit && !canSubmit(value))}
            >
              <Capitalized>
                {loading ? (
                  <CircularProgress size={24} />
                ) : (
                  acceptLabel ?? t('submit')
                )}
              </Capitalized>
            </Button>
          </DialogActions>
        </DialogForm>
      </StyledDialog>
      {notification}
    </>
  );
}
