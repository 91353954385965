import {
  HomeRounded as HomeIcon,
  DomainRounded as AdminIcon,
  PeopleRounded as UsersIcon,
  PersonRounded as UserIcon,
  DoneAllRounded as ActionsIcon
} from '@material-ui/icons';

import { PageDescriptor } from 'framework';
import * as screens from 'screens';

export const pages: Record<string, PageDescriptor> = {
  home: {
    title: 'landing',
    path: '/',
    icon: HomeIcon,
    screen: screens.Home,
    hidden: true,
    authorized: false,
    ignoreContainer: true,
    groups: [],
    noHeader: true
  },
  checkout: {
    title: 'checkout',
    path: '/checkout/:product',
    icon: HomeIcon,
    screen: screens.Checkout,
    hidden: true,
    authorized: false,
    ignoreContainer: true,
    groups: [],
    noHeader: true
  },
  callback: {
    title: 'loggingIn',
    path: '/callback',
    pathParams: [':organization'],
    icon: HomeIcon,
    screen: screens.Callback,
    authorized: false,
    hidden: true,
    noHeader: true
  },
  notFound: {
    title: 'pageNotFound',
    path: '/not-found',
    icon: HomeIcon,
    screen: screens.NotFound,
    authorized: false,
    hidden: true,
    noHeader: true
  },
  paymentSuccess: {
    title: 'paymentSuccess',
    path: '/payment/success',
    icon: HomeIcon,
    screen: screens.Home,
    hidden: true,
    authorized: false,
    groups: [],
    noHeader: true
  },
  paymentFailed: {
    title: 'paymentFailed',
    path: '/payment/failed',
    icon: HomeIcon,
    screen: screens.Home,
    hidden: true,
    authorized: false,
    groups: [],
    noHeader: true
  },
  signin: {
    title: 'signin',
    path: '/signin',
    icon: HomeIcon,
    screen: screens.Signin,
    hidden: true,
    authorized: false,
    groups: [],
    noHeader: true
  },
  dashboard: {
    title: 'dashboard',
    path: '/:organization',
    icon: HomeIcon,
    screen: screens.Dashboard,
    hidden: true,
    authorized: true,
    groups: []
  },
  actions: {
    title: 'actions',
    path: '/:organization/actions',
    icon: ActionsIcon,
    screen: screens.Actions,
    authorized: true,
    groups: []
  },
  user: {
    title: 'profile',
    path: '/:organization/user/:tab?',
    icon: UserIcon,
    screen: screens.User,
    authorized: true
  },
  users: {
    title: 'users',
    path: '/:organization/users/:userId?',
    icon: UsersIcon,
    screen: screens.Users,
    authorized: true,
    groups: ['administrators']
  },
  admin: {
    title: 'administration',
    path: '/:organization/admin/:tab?',
    icon: AdminIcon,
    screen: screens.OrgAdmin,
    authorized: true,
    groups: ['administrators']
  }
};
