import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSpot } from 'framework';
import {
  Row,
  Container,
  FormTextInput,
  FormLabel,
  FormRow,
  Line,
  TitleContainer,
  Capitalized
} from 'components';

export function LeaveRemaining({
  id,
  organization
}: {
  id: string;
  organization: string;
}) {
  const { t } = useTranslation();
  const { data, query } = useSpot();

  const user = data?.users?.[id];
  const org = data?.organizations?.[organization];
  const shouldLookupOrg = !data?.organizations?.[organization];

  useEffect(() => {
    (async () => {
      if (shouldLookupOrg) {
        await query(`org/${organization}`, {}, ['organizations', organization]);
      }
      await query(`org/${organization}/user/${id}`, {}, ['users', id]);
    })();
  }, [query, organization, id, shouldLookupOrg]);

  return (
    <>
      <TitleContainer>
        <Capitalized variant="h5">{t('leaveRemaining')}</Capitalized>
      </TitleContainer>
      <Line />
      <Row>
        <Container>
          {Object.entries(user?.leaveRemaining ?? {})
            .filter(([type]) => !org?.disabledLeaveTypes.includes(type))
            .map(([type, amount]) => (
              <FormRow key={type}>
                <FormLabel>{t(type)}</FormLabel>
                <FormTextInput
                  disabled
                  initialValue={`${amount ?? ''} ${
                    user?.leaveTypes?.[type]
                      ? `${t('of')} ${
                          user?.useCustomLeaveTypes
                            ? user?.leaveTypes?.[type]
                            : org?.leaveTypes?.[type]
                        }`
                      : ''
                  }`}
                  endAdornment={t('days')}
                />
              </FormRow>
            ))}
        </Container>
      </Row>
    </>
  );
}
