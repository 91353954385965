import React, { ReactChild, useRef, MutableRefObject } from 'react';
import {
  Table as MaterialTable,
  TableHead as MaterialTableHead,
  TableBody as MaterialTableBody,
  TableRow as MaterialTableRow,
  TableCell as MaterialTableCell,
  Typography,
  Box
} from '@material-ui/core';
import styled from 'styled-components';

const StyledTable = styled(MaterialTable)`
  border-collapse: collapse;
`;

export const StyledHeader = styled(Typography)`
  margin: 8px 0;
  height: 24px;
  color: ${p => p.theme.palette.text.secondary};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: capitalize;
`;

export const StyledTableHeader = styled(MaterialTableCell)<{
  mincolwidth?: number;
}>`
  min-width: ${p => (p.mincolwidth ? `${p.mincolwidth}px` : '')};
  padding: ${p => p.theme.spacing(0, 2)};
  border-bottom: 1px solid ${p => p.theme.palette.background.default};
`;

export const StyledTableRow = styled(MaterialTableRow)<{ clickable?: string }>`
  cursor: ${p => (p.clickable === 'true' ? 'pointer' : 'unset')};
`;

export const StyledTableCell = styled(MaterialTableCell)<{
  disabled?: boolean;
}>`
  color: ${p =>
    p.disabled ? p.theme.palette.text.disabled : p.theme.palette.text.primary};

  border-bottom: 1px solid ${p => p.theme.palette.background.default};
  :first-child {
    border-left: 1px solid ${p => p.theme.palette.background.default};
  }
  :last-child {
    border-right: 1px solid ${p => p.theme.palette.background.default};
  }

  position: relative;
`;

const Container = styled(Box)<{
  maxHeight?: number | string;
  ref?: MutableRefObject<HTMLElement | undefined>;
}>`
  overflow: hidden auto;
  padding: 0 1px;
  max-height: ${p => p.maxHeight};
`;

type TableCell = ReactChild;
type TableHeaderCell = string | HeaderCell;
export type TableRow = string[] | RowDescriptor;

interface HeaderCell {
  id: string;
  content: ReactChild;
}

export interface RowDescriptor {
  id: string;
  cells: TableCell[];
  disabled?: boolean;
}

interface Props<T> {
  headers?: TableHeaderCell[];
  rows: (TableRow | T)[];
  renderCustomRow?: (row: T) => ReactChild;
  renderEmptyContent?: () => ReactChild;
  maxHeight?: number | string;
  minColWidth?: number;
  onScrollToEnd?: () => void;
  onRowClick?: (row: RowDescriptor) => void;
}

export function Table<T>({
  headers,
  rows,
  renderCustomRow,
  renderEmptyContent,
  maxHeight,
  minColWidth,
  onScrollToEnd,
  onRowClick
}: Props<T>) {
  const scrollContainer = useRef<HTMLElement>();

  const onScroll = () => {
    if (
      onScrollToEnd &&
      !!scrollContainer.current &&
      scrollContainer.current.offsetHeight +
        scrollContainer.current.scrollTop >=
        scrollContainer.current.scrollHeight
    ) {
      onScrollToEnd();
    }
  };

  const columnKeys = headers?.map((header: TableHeaderCell) => {
    if (typeof header === 'string') return header;

    const headerObject = header as HeaderCell;
    return headerObject.id;
  }) || ['1', '2', '3', '4', '5', '6', '7', '8', '9'];

  const renderHeader = (header: TableHeaderCell) => {
    if (typeof header === 'string') {
      return (
        <StyledTableHeader key={header} mincolwidth={minColWidth}>
          <StyledHeader>{header}</StyledHeader>
        </StyledTableHeader>
      );
    }

    const headerObject = header as HeaderCell;
    return (
      <StyledTableHeader key={headerObject.id} mincolwidth={minColWidth}>
        {headerObject.content}
      </StyledTableHeader>
    );
  };

  const renderRow = (row: TableRow | T) => {
    const rowObject = row as RowDescriptor;

    if (!rowObject.id && !rowObject.cells) {
      const rowArray = row as string[];

      const isArray = Array.isArray(rowObject);
      const containsStrings =
        isArray && rowArray.every(entry => typeof entry === 'string');

      if (!containsStrings) {
        throw Error(
          'A row should either be an array of string or a Row descriptor object!!'
        );
      }

      if (onRowClick) {
        throw Error(
          'onRowClick was provided with a string[] row type, this only applies to Row type with an ID!!'
        );
      }

      return (
        <StyledTableRow key={btoa(JSON.stringify(rowArray))}>
          {rowArray.map((rowCell: string, index: number) => (
            <StyledTableCell key={`${columnKeys[index]}`} disabled={false}>
              {rowCell}
            </StyledTableCell>
          ))}
        </StyledTableRow>
      );
    }

    return (
      <StyledTableRow
        key={rowObject.id}
        hover={!!onRowClick}
        clickable={`${!!onRowClick}`}
        onClick={() => onRowClick && onRowClick(rowObject)}
      >
        {rowObject.cells.map((rowCell: ReactChild, index: number) => (
          <StyledTableCell
            key={`${columnKeys[index]}`}
            disabled={rowObject.disabled}
          >
            {rowCell}
          </StyledTableCell>
        ))}
      </StyledTableRow>
    );
  };

  const onRenderEmpty = () => (
    <StyledTableRow key="empty">
      <StyledTableCell colSpan={headers?.length ?? 1}>
        {renderEmptyContent ? renderEmptyContent() : 'No items'}
      </StyledTableCell>
    </StyledTableRow>
  );

  return (
    <Container maxHeight={maxHeight} ref={scrollContainer} onScroll={onScroll}>
      <StyledTable stickyHeader>
        {headers && (
          <MaterialTableHead>
            <MaterialTableRow>{headers.map(renderHeader)}</MaterialTableRow>
          </MaterialTableHead>
        )}
        <MaterialTableBody>
          {rows.length
            ? rows.map(row =>
                renderCustomRow
                  ? renderCustomRow(row as T)
                  : renderRow(row as TableRow)
              )
            : onRenderEmpty()}
        </MaterialTableBody>
      </StyledTable>
    </Container>
  );
}
