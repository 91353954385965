import React from 'react';
import styled from 'styled-components';
import { Box, LinearProgress } from '@material-ui/core';

const FakeLoader = styled(Box)`
  height: 4px;
  width: 100%;
`;

interface Props {
  loading: boolean;
  value?: number;
  valueBuffer?: number;
  variant?: 'buffer' | 'determinate' | 'indeterminate' | 'query';
  color?: 'primary' | 'secondary';
}

export function HorizontalLoadingBar({
  loading,
  value,
  valueBuffer,
  variant,
  color
}: Props) {
  return (
    <>
      {loading ? (
        <LinearProgress
          value={value}
          valueBuffer={valueBuffer}
          variant={variant}
          color={color}
        />
      ) : (
        <FakeLoader />
      )}
    </>
  );
}
