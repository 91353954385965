import React, { useCallback, useRef } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  AppBar,
  Box,
  Button,
  Container,
  Hidden,
  Toolbar,
  Typography
} from '@material-ui/core';
import styled from 'styled-components';
import Carousel from 'react-material-ui-carousel';

import { Gap, Row, Spacer } from 'components';
import { FadeComponent } from 'components/fader';
import image1 from '../images/reading-corner.jpg';

import image2a from '../images/promo1.jpg';
import image2b from '../images/promo2.jpg';
import image2c from '../images/promo3.jpg';
import image2d from '../images/promo4.jpg';
import image2e from '../images/promo5.jpg';

import image3 from '../images/phone-conversation.jpg';
import image5 from '../images/handshake.jpg';

import logo from '../images/logo.png';

import checkoutSmall from '../images/checkout-small.jpg';
import checkoutStartup from '../images/checkout-startup.jpg';
import checkoutLarge from '../images/checkout-large.jpg';

const promoImages = [image2a, image2b, image2c, image2d, image2e];

const StyledToolbar = styled(Toolbar)`
  display: flex;
`;

const CenteredRow = styled(Row)`
  justify-content: center;
`;

const BigGap = styled(Box)`
  margin: ${p => p.theme.spacing(8, 0)};
`;

const WhiteSection = styled(Box)`
  background-color: #fff;
  padding: ${p => p.theme.spacing(8, 2)};
`;

const AltSection = styled(Box)`
  background-color: #f7f7f7;
  padding: ${p => p.theme.spacing(8, 2)};
`;

const TextBlock = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 480px;
  padding: ${p => p.theme.spacing(10, 0)};
`;

const CheckoutBlock = styled(Box)`
  flex: 0 1 280px;
  height: 520px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${p => p.theme.spacing(2)}px;
  border-radius: 5px;
  border: 2px solid #f7f7f7;
  box-shadow: ${p => p.theme.shadows[2]};
  cursor: pointer;
  margin: ${p => p.theme.spacing(2, 2)};

  :hover {
    transform: scale(1.05);
    box-shadow: 0px 0px 15px 5px #f0f0ff;
  }
`;

const CheckoutButton = styled(Button)`
  width: 200px;
`;

const CalloutButton = styled(Button)`
  border-radius: 20px;
  padding: ${p => p.theme.spacing(1, 2)}
  background-color: #ffffff;
  margin-right: ${p => p.theme.spacing(2)}px;
`;

const ContrastButton = styled(Button)`
  color: ${p => p.theme.palette.primary.contrastText};
  margin-right: ${p => p.theme.spacing(4)}px;
`;

export function Home() {
  const { t } = useTranslation();
  const history = useHistory();
  const root = useRef<HTMLElement>();

  const checkout = useCallback(
    (product: string) => {
      gtag('event', 'checkout-click');
      history.push(`/checkout/${product}${history.location.search}`);
    },
    [history]
  );

  const signin = useCallback(() => {
    gtag('event', 'signin-click');
    history.push(`/signin${history.location.search}`);
  }, [history]);

  const scrollTo = (id: string) => {
    const element = document.getElementById(id);
    const scrollBase =
      root.current?.parentElement?.parentElement?.parentElement;
    if (!element || !scrollBase) {
      return;
    }
    const y =
      element.getBoundingClientRect().top + scrollBase.scrollTop - 80 - 16;
    scrollBase.scroll({
      top: y,
      behavior: 'smooth'
    });
  };

  return (
    <>
      <AppBar position="fixed" ref={root}>
        <Container>
          <StyledToolbar>
            <img src={logo} alt={t('logo')} height={48} width={48} />
            <Box mr={2} />
            <Hidden smDown>
              <Typography variant="h5">{t('spotHr')}</Typography>
            </Hidden>
            <Spacer />
            <ContrastButton onClick={signin}>{t('signin')}</ContrastButton>
            <CalloutButton
              variant="contained"
              onClick={() => scrollTo('sectionCheckout')}
            >
              {t('signup')}
            </CalloutButton>
          </StyledToolbar>
        </Container>
      </AppBar>
      <WhiteSection id="section1">
        <Container>
          <Row>
            <TextBlock>
              <Typography variant="h3" color="primary">
                {t('homeSection1')}
              </Typography>
              <Gap />
              <Typography variant="body1">{t('homeSection1Desc')}</Typography>
            </TextBlock>
            <Spacer />
            <FadeComponent>
              <img
                src={image1}
                alt={t('homeImage1')}
                width="100%"
                style={{ maxWidth: '600px' }}
              />
            </FadeComponent>
          </Row>

          <BigGap />

          <Row>
            <Typography variant="body1" align="center">
              {t('homeSection1Details')}
            </Typography>
          </Row>
        </Container>
      </WhiteSection>

      <AltSection id="section2">
        <Container>
          <Typography variant="h3" color="primary" align="center">
            {t('homeSection2')}
          </Typography>
          <Gap />
          <Typography variant="body1" align="center">
            {t('homeSection2Desc')}
          </Typography>
          <BigGap />
          <CenteredRow>
            <Hidden smDown>
              <Carousel>
                {promoImages.map(imagePath => (
                  <img
                    key={imagePath}
                    src={imagePath}
                    alt={t('homeImage2')}
                    height={600}
                    width={1002}
                  />
                ))}
              </Carousel>
            </Hidden>
            <Hidden mdUp xsDown>
              <Carousel>
                {promoImages.map(imagePath => (
                  <img
                    key={imagePath}
                    src={imagePath}
                    alt={t('homeImage2')}
                    height={355}
                    width={593}
                  />
                ))}
              </Carousel>
            </Hidden>
            <Hidden smUp>
              <Carousel>
                {promoImages.map(imagePath => (
                  <img
                    key={imagePath}
                    src={imagePath}
                    alt={t('homeImage2')}
                    height={205}
                    width={342}
                  />
                ))}
              </Carousel>
            </Hidden>
          </CenteredRow>
        </Container>
      </AltSection>

      <WhiteSection id="section3">
        <Container>
          <Row>
            <FadeComponent>
              <img
                src={image3}
                alt={t('homeImage3')}
                width="100%"
                style={{ maxWidth: '600px' }}
              />
            </FadeComponent>
            <Spacer />
            <TextBlock>
              <Typography variant="h3" color="primary">
                {t('homeSection3')}
              </Typography>
              <Gap />
              <Typography variant="body1">{t('homeSection3Desc')}</Typography>
            </TextBlock>
          </Row>

          <BigGap />

          <Row>
            <Typography variant="body1" align="center">
              {t('homeSection3Details')}
            </Typography>
          </Row>
        </Container>
      </WhiteSection>

      <AltSection id="section5">
        <Container>
          <Row>
            <TextBlock>
              <Typography variant="h3" color="primary">
                {t('homeSection5')}
              </Typography>
              <Gap />
              <Typography variant="body1">{t('homeSection5Desc')}</Typography>
            </TextBlock>
            <Spacer />
            <FadeComponent>
              <img
                src={image5}
                alt={t('homeImage5')}
                width="100%"
                style={{ maxWidth: '600px' }}
              />
            </FadeComponent>
          </Row>

          <BigGap />

          <Row>
            <Typography variant="body1" align="center">
              {t('homeSection5Details')}
            </Typography>
          </Row>
        </Container>
      </AltSection>

      <WhiteSection>
        <Container id="sectionCheckout">
          <Typography variant="h4" color="primary" align="center">
            {t('homeSection4')}
          </Typography>
          <Gap />
          <Typography variant="body1" align="center">
            {t('homeSection4Desc1')}
            <br />
            {t('homeSection4Desc2')}
          </Typography>

          <BigGap />

          <CenteredRow>
            <CheckoutBlock onClick={() => checkout('small')}>
              <Typography variant="h4" align="center">
                {t('small')}
              </Typography>
              <Gap />
              <Typography variant="subtitle1" align="center">
                {t('smallDesc')}
              </Typography>
              <Gap />
              <Typography variant="body1" color="secondary" align="center">
                {t('thirtyDaysFree')}
              </Typography>
              <Gap />
              <Typography variant="h4" color="primary" align="center">
                {t('smallPrice')}
              </Typography>
              <Typography variant="caption" color="primary" align="center">
                {t('perMonth')}
              </Typography>
              <Spacer />
              <img
                src={checkoutSmall}
                alt={t('small')}
                style={{ maxHeight: '200px' }}
              />
              <Spacer />
              <CheckoutButton
                variant="contained"
                color="secondary"
                size="large"
              >
                {t('checkoutSmall')}
              </CheckoutButton>
            </CheckoutBlock>
            <CheckoutBlock onClick={() => checkout('startup')}>
              <Typography variant="h4" align="center">
                {t('startup')}
              </Typography>
              <Gap />
              <Typography variant="subtitle1" align="center">
                {t('startupDesc')}
              </Typography>
              <Gap />
              <Typography variant="body1" color="secondary" align="center">
                {t('thirtyDaysFree')}
              </Typography>
              <Gap />
              <Typography variant="h4" color="primary" align="center">
                {t('startupPrice')}
              </Typography>
              <Typography variant="caption" color="primary" align="center">
                {t('perMonth')}
              </Typography>
              <Spacer />
              <img
                src={checkoutStartup}
                alt={t('startup')}
                style={{ maxHeight: '200px' }}
              />
              <Spacer />
              <CheckoutButton
                variant="contained"
                color="secondary"
                size="large"
              >
                {t('checkoutStartup')}
              </CheckoutButton>
            </CheckoutBlock>
            <CheckoutBlock onClick={() => checkout('large')}>
              <Typography variant="h4" align="center">
                {t('large')}
              </Typography>
              <Gap />
              <Typography variant="subtitle1" align="center">
                {t('largeDesc')}
              </Typography>
              <Gap />
              <Typography variant="body1" color="secondary" align="center">
                {t('thirtyDaysFree')}
              </Typography>
              <Gap />
              <Typography variant="h4" color="primary" align="center">
                {t('largePrice')}
              </Typography>
              <Typography variant="caption" color="primary" align="center">
                {t('perMonth')}
              </Typography>
              <Spacer />
              <img
                src={checkoutLarge}
                alt={t('large')}
                style={{ maxHeight: '200px' }}
              />
              <Spacer />
              <CheckoutButton
                variant="contained"
                color="secondary"
                size="large"
              >
                {t('checkoutLarge')}
              </CheckoutButton>
            </CheckoutBlock>
          </CenteredRow>
        </Container>
      </WhiteSection>
      <BigGap />
    </>
  );
}
