import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { useParams, useHistory } from 'react-router';
import moment from 'moment';
import { Box, Button, IconButton, Switch, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  DeleteRounded as DeleteIcon,
  AddRounded as AddIcon,
  EditRounded as EditIcon
} from '@material-ui/icons';

import {
  AuthContext,
  baseUrl,
  formatNumber,
  useFile,
  useSpot,
  validateEmail
} from 'framework';
import {
  FormTextInput,
  FormLabel,
  FormRow,
  Container,
  Row,
  CroppedImage,
  FormNumberInput,
  TitleContainer,
  Capitalized,
  Line,
  FormFieldWrapper,
  Tabs,
  Table,
  Spacer,
  PublicHolidayResult,
  EditPublicHolidayDialog,
  ConfirmDialog,
  Gap,
  PermanentlyDeleteDialog
} from 'components';

const InputPostText = styled(Typography)`
  padding: ${p => p.theme.spacing(0, 1)};
  white-space: nowrap;
`;

const StyledButton = styled(Button)`
  margin: ${p => p.theme.spacing(2, 2)};
  padding: ${p => p.theme.spacing(2)}px;
`;

interface AddPublicHolidayDialogDesc {
  name?: string;
  date?: string;
  callback: (publicHoliday: PublicHolidayResult) => Promise<void>;
}

interface ConfirmDialogDesc {
  callback: () => Promise<void>;
}

interface DeleteDialogDesc {
  callback: () => Promise<void>;
}

export function OrgAdmin() {
  const fileInput = useRef<HTMLInputElement>(null);
  const { getToken } = useContext(AuthContext);
  const { organization, tab } = useParams<{
    organization: string;
    tab?: string;
  }>();

  const history = useHistory();

  const handleTabChange = (selectedTab: string) => {
    history.replace(`/${organization}/admin/${selectedTab}`);
  };

  const [
    publicHolidayDialog,
    setPublicHolidayDialog
  ] = useState<AddPublicHolidayDialogDesc | null>(null);
  const [confirmDialog, setConfirmDialog] = useState<ConfirmDialogDesc | null>(
    null
  );

  const [deleteDialog, setDeleteDialog] = useState<DeleteDialogDesc | null>(
    null
  );

  const { t } = useTranslation();

  const { data, errors, query, command, loading } = useSpot();

  useEffect(() => {
    if (errors.find(err => err.status === 401)) {
      window.location.reload();
    }
  }, [errors]);

  useEffect(() => {
    (async () => {
      await query(`org/${organization}`, {}, ['organizations', organization]);
      await query(`org/${organization}/subscriptions`, {}, [
        'organizations',
        organization,
        'subscriptions'
      ]);
    })();
  }, [organization, query]);

  const org = data?.organizations?.[organization];
  const icon = org?.icon;

  const updateOrg = async (
    field: string,
    value: string | number | { [k: string]: number } | string[]
  ) => {
    await command(`org/${organization}`, {
      [field]: value
    });
    await query(`org/${organization}`, {}, ['organizations', organization]);
  };

  const uploadIcon = async (file: File) => {
    if (!file) {
      return;
    }

    // Fetch upload url
    const uploadResponse = await fetch(
      `${baseUrl}/org/${organization}/getPublicFileUpload/${file.name}`,
      {
        headers: {
          authorization: getToken().getJwtToken()
        },
        method: 'get'
      }
    );

    const { url } = await uploadResponse.json();

    // Upload
    await fetch(url, {
      method: 'put',
      body: file
    });

    await command(`org/${organization}`, {
      icon: `${file.name}`
    });

    await query(`org/${organization}`, {}, ['organizations', organization]);
  };

  const handleFileSelected = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.files?.length && uploadIcon(e.target.files[0]);
  };

  const onDeletePublicHoliday = (id: string) => {
    setConfirmDialog({
      callback: async () => {
        await command(
          `org/${organization}/publicHoliday/${id}`,
          {},
          { method: 'DELETE' }
        );
        await query(`org/${organization}`, {}, ['organizations', organization]);
        setConfirmDialog(null);
      }
    });
  };

  const onAddPublicHoliday = () => {
    setPublicHolidayDialog({
      callback: async publicHoliday => {
        await command(`org/${organization}/publicHoliday`, {
          ...publicHoliday
        });
        await query(`org/${organization}`, {}, ['organizations', organization]);
        setPublicHolidayDialog(null);
      }
    });
  };

  const onEditPublicHoliday = (id: string, date: string, name: string) => {
    setPublicHolidayDialog({
      name,
      date,
      callback: async publicHoliday => {
        await command(`org/${organization}/publicHoliday/${id}`, {
          ...publicHoliday
        });
        await query(`org/${organization}`, {}, ['organizations', organization]);
        setPublicHolidayDialog(null);
      }
    });
  };

  const onDeleteOrganization = () => {
    setDeleteDialog({
      callback: async () => {
        await command(`org/${organization}`, {}, { method: 'DELETE' });
        setConfirmDialog(null);
        window.location.href = `https://spothr.net`;
      }
    });
  };

  const { url: imageUrl } = useFile(icon || '', organization);

  const tabs = {
    admin: (
      <>
        <TitleContainer>
          <Typography variant="h5">{t('administration')}</Typography>
        </TitleContainer>
        <Line />
        <Row>
          <Container>
            <FormRow>
              <FormLabel>{`${t('name')}:`}</FormLabel>
              <FormTextInput
                initialValue={org?.name}
                onChange={(value: string) => updateOrg('name', value)}
              />
            </FormRow>
            <FormRow>
              <FormLabel>{`${t('description')}:`}</FormLabel>
              <FormTextInput
                multiline
                rows={3}
                rowsMax={3}
                rowsMin={3}
                initialValue={org?.description}
                onChange={(value: string) => updateOrg('description', value)}
              />
            </FormRow>
            <FormRow>
              <FormLabel>{`${t('ownerEmail')}:`}</FormLabel>
              <FormTextInput
                initialValue={org?.ownerEmail}
                validate={validateEmail}
                onChange={(value: string) => updateOrg('ownerEmail', value)}
              />
            </FormRow>
          </Container>
          <Box>
            <StyledButton
              variant="outlined"
              onClick={() => fileInput.current?.click()}
            >
              <CroppedImage
                width={256}
                height={256}
                url={imageUrl}
                alt={org?.name}
              />
            </StyledButton>
            <input
              ref={fileInput}
              type="file"
              onChange={handleFileSelected}
              style={{ display: 'none' }}
            />
          </Box>
        </Row>
      </>
    ),
    leaveConfig: (
      <>
        <TitleContainer>
          <Capitalized variant="h5">{t('leaveTypes')}</Capitalized>
        </TitleContainer>
        <Typography variant="body2">{t('leaveTypesDescription')}</Typography>
        <Line />
        {Object.entries(org?.leaveTypes ?? {})?.map(([type, hours]) => (
          <FormRow key={type}>
            <FormLabel>{`${t(type)}:`}</FormLabel>
            <Box display="flex">
              <FormNumberInput
                initialValue={hours}
                min={0}
                max={365}
                endAdornment={
                  <InputPostText variant="caption">
                    {t('perYear')}
                  </InputPostText>
                }
                onChange={(value: number) =>
                  updateOrg('leaveTypes', {
                    ...(org?.leaveTypes ?? {}),
                    [type]: value
                  })
                }
              />
              <FormFieldWrapper>
                <Switch
                  checked={!org?.disabledLeaveTypes.includes(type)}
                  onChange={(
                    event: React.ChangeEvent<{ checked: boolean }>
                  ) => {
                    const currentLeaveTypes = org?.disabledLeaveTypes ?? [];
                    if (event.target.checked) {
                      currentLeaveTypes.splice(
                        currentLeaveTypes.indexOf(type),
                        1
                      );
                      updateOrg('disabledLeaveTypes', [...currentLeaveTypes]);
                    } else {
                      updateOrg('disabledLeaveTypes', [
                        ...currentLeaveTypes,
                        type
                      ]);
                    }
                  }}
                />
              </FormFieldWrapper>
            </Box>
          </FormRow>
        ))}
      </>
    ),
    publicHolidays: (
      <>
        <TitleContainer>
          <Capitalized variant="h5">{t('publicHolidays')}</Capitalized>
        </TitleContainer>
        <Typography variant="body2">
          {t('publicHolidaysDescription')}
        </Typography>
        <Line />
        <Row mb={1}>
          <Spacer />
          <Button
            color="primary"
            startIcon={<AddIcon />}
            aria-label="add"
            onClick={onAddPublicHoliday}
          >
            {t('addPublicHoliday')}
          </Button>
        </Row>
        <Table
          maxHeight="580px"
          headers={[t('date'), t('name'), '']}
          rows={
            org?.publicHolidays
              .sort((dayA, dayB) => {
                return dayA.date.localeCompare(dayB.date);
              })
              .map(day => ({
                id: day.id,
                cells: [
                  moment(day.date, 'DD-MM').format('D MMMM'),
                  day.name,
                  <>
                    <IconButton
                      onClick={() =>
                        onEditPublicHoliday(day.id, day.date, day.name)
                      }
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => onDeletePublicHoliday(day.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </>
                ]
              })) ?? []
          }
        />
        <EditPublicHolidayDialog
          title={t('editPublicHolidayTitle')}
          text={t('editPublicHolidaySubtitle')}
          initialValue={{
            name: publicHolidayDialog?.name,
            date: publicHolidayDialog?.date
          }}
          dialogOpen={!!publicHolidayDialog}
          onClose={() => setPublicHolidayDialog(null)}
          onSubmit={publicHolidayDialog?.callback}
        />
        <ConfirmDialog
          title={t('confirmPublicHolidayDeleteTitle')}
          text={t('confirmPublicHolidayDeleteSubtitle')}
          dialogOpen={!!confirmDialog}
          onClose={() => setConfirmDialog(null)}
          onSubmit={confirmDialog?.callback}
        />
      </>
    ),
    hourConfig: (
      <>
        <TitleContainer>
          <Capitalized variant="h5">{t('hours')}</Capitalized>
        </TitleContainer>
        <Typography variant="body2">{t('orgHoursSettings')}</Typography>
        <Line />
        <Gap />
        <FormRow>
          <FormLabel>{`${t('orgHoursPerDay')}:`}</FormLabel>
          <FormNumberInput
            initialValue={org?.hoursPerDay}
            onChange={(value: number) => updateOrg('hoursPerDay', value)}
          />
        </FormRow>
        <FormRow>
          <FormLabel>{`${t('orgHoursPerWeek')}:`}</FormLabel>
          <FormNumberInput
            initialValue={org?.hoursPerWeek}
            onChange={(value: number) => updateOrg('hoursPerWeek', value)}
          />
        </FormRow>
      </>
    ),
    billing: (
      <>
        <TitleContainer>
          <Capitalized variant="h5">{t('billing')}</Capitalized>
        </TitleContainer>
        <Line />

        {org?.subscriptions?.map(sub => (
          <Box key={sub.startDate}>
            <FormRow>
              <FormLabel>{t('startDate')}</FormLabel>
              <FormTextInput
                disabled
                initialValue={`${moment(sub.startDate).format('LL') ?? ''}`}
              />
            </FormRow>
            <FormRow>
              <FormLabel>{t('lastBillDate')}</FormLabel>
              <FormTextInput
                disabled
                initialValue={`${moment(sub.lastBillDate).format('LL') ?? ''}`}
              />
            </FormRow>
            <FormRow>
              <FormLabel>{t('nextBillDate')}</FormLabel>
              <FormTextInput
                disabled
                initialValue={`${moment(sub.nextBillDate).format('LL') ?? ''}`}
              />
            </FormRow>
            <FormRow>
              <FormLabel>{t('status')}</FormLabel>
              <FormTextInput
                disabled
                initialValue={`${sub.status ? t(sub.status) : t('unknown')}`}
              />
            </FormRow>
            <Gap />
            <Table
              headers={[t('product'), t('price')]}
              rows={sub.products.map(product => ({
                id: product.name,
                cells: [
                  t(product.name),
                  formatNumber(product.price / 100, {
                    currency: product.currency,
                    style: 'currency'
                  })
                ]
              }))}
            />
          </Box>
        ))}
        <TitleContainer>
          <Capitalized variant="h5">{t('dangerZone')}</Capitalized>
        </TitleContainer>
        <Line />
        <FormRow>
          <Typography variant="body2">{t('deleteOrganizationDesc')}</Typography>
          <Spacer />
          <Button
            variant="contained"
            color="secondary"
            onClick={onDeleteOrganization}
          >
            {t('deleteOrganization')}
          </Button>
        </FormRow>
        <PermanentlyDeleteDialog
          title={t('deleteOrganizationTitle')}
          text={t('deleteOrganizationSubtitle')}
          validationWord={organization}
          dialogOpen={!!deleteDialog}
          onClose={() => setDeleteDialog(null)}
          onSubmit={deleteDialog?.callback}
        />
      </>
    )
  };

  return (
    <>
      <Container>
        <Tabs
          selected={tab}
          onTabChange={handleTabChange}
          loading={loading}
          tabs={tabs}
        />
      </Container>
    </>
  );
}
