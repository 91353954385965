import React from 'react';
import { TextField } from '@material-ui/core';
import { CustomDialog } from './custom-dialog';

interface Props {
  title: string;
  initialValue?: number;
  dialogOpen: boolean;
  onSubmit?: (name: number) => Promise<void>;
  onClose?: () => unknown;
  min?: number;
  max?: number;
}

export const EditNumberDialog = (props: Props) => {
  const { min, max } = props;
  const renderControl = (setValue: (val?: number) => void, value?: number) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(Number.parseFloat(e.target.value));
    };

    return (
      <TextField
        required
        error={value === null || value === undefined}
        value={value}
        onChange={handleChange}
        autoFocus
        placeholder="Please enter an amount"
        margin="dense"
        id="amount"
        label="Amount"
        type="number"
        InputProps={{ inputProps: { min, max } }}
        fullWidth
        helperText={
          (value === null || value === undefined) && 'Amount is required'
        }
      />
    );
  };

  return CustomDialog({ ...props, renderControl });
};
