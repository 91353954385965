import { Box } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

export const Row = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
