import { useCallback } from 'react';
import { debounce } from 'debounce';

export function useDebounce(
  callback: (...args: any[]) => unknown,
  delay: number
) {
  const debouncedFn = useCallback(
    debounce((...args: any[]) => callback(...args), delay),
    [delay]
  );
  return debouncedFn;
}
