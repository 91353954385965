import { Box, Typography } from '@material-ui/core';
import styled from 'styled-components';

export const Capitalized = styled(Typography)`
  text-transform: capitalize;
`;

export const TitleContainer = styled(Box)`
  margin-bottom: ${p => p.theme.spacing(2)}px;
  margin-top: ${p => p.theme.spacing(4)}px;
`;

export const Line = styled(Box)`
  margin: ${p => p.theme.spacing(2, 0)};
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
`;

export const FullWidthContent = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 240px;
`;

export const LimitedText = styled(Typography)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const VerticalContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;
