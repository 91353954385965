import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Box, IconButton, Typography, Button } from '@material-ui/core';
import {
  DescriptionRounded as FileDocumentIcon,
  ImageRounded as FileImageIcon,
  FolderRounded as FolderIcon,
  ArrowBackRounded as FolderUpIcon,
  CloudDownloadRounded as DownloadIcon,
  DeleteRounded as DeleteIcon
} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { Document, Page, pdfjs } from 'react-pdf';

import { ConfirmDialog, Spacer, Gap } from 'components';
import { AuthContext, useFile, useSpot } from 'framework';
import { UserFile } from 'domain-types';

import noFilesImg from 'images/deskDog.jpg';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const NoActionsBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled(Typography)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const DocumentScrollBox = styled(Box)`
  max-height: 55vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${p => p.theme.spacing(4, 0)};
  background-color: #ccc;
  width: 100%;
`;

const TitleRow = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`;

const FileGrid = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-height: 800px;
  overflow: auto;
`;

const File = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 256px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 200px;
  margin: ${p => p.theme.spacing(2)}px;
`;

const FileButton = styled(Button)`
  font-size: 160px;
`;

const FileContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${p => p.theme.spacing(4)}px;
`;

const FileName = styled(Typography)`
  margin-top: ${p => p.theme.spacing(1)}px;
  max-width: 160px;
  overflow: hidden;
`;

const FixedWidthButton = styled(Button)`
  flex: 0 0 160px;
`;

interface ConfirmDialogDesc {
  title: string;
  text: string;
  callback: () => Promise<void>;
  cancel?: () => unknown;
}

interface FileDesc {
  name: string;
  lastModified: string;
  size: string;
  path: string;
}

interface Folder {
  [k: string]: Folder | FileDesc;
}

interface Props {
  organization: string;
  id: string;
}

export function FileBrowser({ organization, id }: Props) {
  const { t } = useTranslation();
  const { isAdministrator } = useContext(AuthContext);
  const [fileBrowserPath, setFileBrowserPath] = useState<string[]>([]);
  const [
    confirmFileDeleteDialog,
    setConfirmFileDeleteDialog
  ] = useState<ConfirmDialogDesc | null>(null);

  const { data, query, command } = useSpot();

  useEffect(() => {
    (async () => {
      await query(`org/${organization}/user/${id}`, {}, ['users', id]);
    })();
  }, [organization, id, query]);

  const files = (data?.users?.[id]?.files || []) as UserFile[];

  const onDeleteFile = async (filename: string) => {
    return new Promise<void>((resolve, reject) => {
      setConfirmFileDeleteDialog({
        title: t('confirmUserFileDeleteTitle'),
        text: t('confirmUserFileDeleteSubtitle'),
        callback: async () => {
          await command(
            `org/${organization}/user/${id}/file/${filename}`,
            {},
            { method: 'DELETE' }
          );
          resolve();
          await query(`org/${organization}/user/${id}`, {}, ['users', id]);
          setConfirmFileDeleteDialog(null);
        },
        cancel: () => {
          setConfirmFileDeleteDialog(null);
          reject();
        }
      });
    });
  };

  const isFile = (file: FileDesc | Folder) =>
    file.name && file.lastModified && file.size;

  const openFile = (name: string) => {
    setFileBrowserPath([...fileBrowserPath, name]);
  };

  const folderUp = () => {
    if (fileBrowserPath.length > 0) {
      const newPath = [...fileBrowserPath];
      newPath.splice(fileBrowserPath.length - 1, 1);
      setFileBrowserPath([...newPath]);
    }
  };

  const deleteFile = async (filename: string) => {
    try {
      await onDeleteFile(filename);
      folderUp();
    } catch (e) {
      // Stop linter whinging.
    }
  };

  const emptyRoot: Folder = {
    [t('images')]: {} as Folder,
    [t('documents')]: {} as Folder,
    [t('other')]: {} as Folder
  };

  const structure = files.reduce((accum, current) => {
    const res = { ...accum };
    const currentName = current.name.substring(1);
    if (
      /.png|.jpg|.gif|.jpeg|.bmp|.svg/.test(current.name.toLocaleLowerCase())
    ) {
      (res[t('images')] as Folder)[currentName] = {
        name: currentName,
        size: current.size,
        lastModified: current.lastModified,
        path: current.name
      };
    } else if (
      /.pdf|.doc|.docx|.odf|.ods|.xls|.xlsx|.ppt|.pptx/.test(
        current.name.toLocaleLowerCase()
      )
    ) {
      (res[t('documents')] as Folder)[currentName] = {
        name: currentName,
        size: current.size,
        lastModified: current.lastModified,
        path: current.name
      };
    } else {
      (res[t('other')] as Folder)[currentName] = {
        name: currentName,
        size: current.size,
        lastModified: current.lastModified,
        path: current.name
      };
    }
    return res;
  }, emptyRoot);

  const activeItem = fileBrowserPath.reduce(
    (accum, current) => (accum as Folder)[current] as Folder | FileDesc,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    structure as any
  ) as Folder | FileDesc;

  const isImage = (file: FileDesc) =>
    /.png|.jpg|.gif|.jpeg|.bmp|.svg/.test(file?.name.toLocaleLowerCase());

  const { url, loading } = useFile(
    isFile(activeItem) ? (activeItem as FileDesc).path.substring(1) : '',
    organization,
    id
  );

  const [pageCount, setPageCount] = useState(0);

  const getFilePanel = (file: FileDesc) => {
    if (loading) {
      return (
        <>
          <Box mt={4} />
          <Typography variant="subtitle1">{t('loading')}</Typography>
        </>
      );
    }
    if (isImage(file)) {
      return <img src={url} alt={(activeItem as FileDesc).name} height={600} />;
    }
    if (/.pdf/.test(file.name)) {
      const renderAllPages = () => {
        const pages = [];
        for (let i = 0; i < pageCount; i++) {
          pages.push(<Page key={i + 1} pageNumber={i + 1} />);
        }
        return pages;
      };
      return (
        <DocumentScrollBox>
          <Document
            file={url}
            onLoadSuccess={({ numPages }: { numPages: number }) =>
              setPageCount(numPages)
            }
          >
            {renderAllPages()}
          </Document>
        </DocumentScrollBox>
      );
    }

    return (
      <>
        <Box mt={4} />
        <Typography variant="subtitle1">{t('noPreviewDownload')}</Typography>
      </>
    );
  };

  return (
    <>
      <Gap />
      <TitleRow>
        <IconButton disabled={fileBrowserPath.length === 0} onClick={folderUp}>
          <FolderUpIcon />
        </IconButton>
        <Box mr={2} />
        <Title variant="h4">
          {isFile(activeItem)
            ? activeItem.name
            : fileBrowserPath[fileBrowserPath.length - 1]}
        </Title>
        <Spacer />
        <Box mr={2} />
        {isFile(activeItem) && isAdministrator() && (
          <FixedWidthButton
            variant="contained"
            color="secondary"
            onClick={() => deleteFile((activeItem as FileDesc).name)}
            title={t('delete')}
            startIcon={<DeleteIcon />}
            disabled={loading}
          >
            {t('delete')}
          </FixedWidthButton>
        )}
        <Box mr={2} />
        {isFile(activeItem) && (
          <FixedWidthButton
            variant="contained"
            color="primary"
            onClick={() => {
              const link = document.createElement('a');
              link.download = (activeItem as FileDesc).name;
              link.href = url;
              link.click();
            }}
            startIcon={<DownloadIcon fontSize="large" />}
            disabled={loading}
          >
            {t('download')}
          </FixedWidthButton>
        )}
      </TitleRow>

      {// eslint-disable-next-line no-nested-ternary
      isFile(activeItem) ? (
        <Box>
          <FileContainer>{getFilePanel(activeItem as FileDesc)}</FileContainer>
        </Box>
      ) : Object.entries(activeItem).length ? (
        <FileGrid>
          {Object.entries(activeItem)
            .sort(([keyA, fileA], [keyB, fileB]) => {
              if (isFile(fileA) && isFile(fileB)) {
                return (fileB as FileDesc)?.lastModified?.localeCompare(
                  (fileA as FileDesc)?.lastModified
                );
              }
              return keyA.localeCompare(keyB);
            })
            .map(([key, file]) => {
              const parsedFile = isFile(file) ? (file as FileDesc) : null;
              const FileIcon = isImage(parsedFile as FileDesc)
                ? FileImageIcon
                : FileDocumentIcon;

              const Icon = parsedFile ? FileIcon : FolderIcon;
              const name = parsedFile ? parsedFile.name : key;

              return (
                <File title={name} key={name}>
                  <FileButton variant="outlined" onClick={() => openFile(name)}>
                    <Icon fontSize="inherit" />
                  </FileButton>
                  <FileName variant="caption">{name}</FileName>
                </File>
              );
            })}
        </FileGrid>
      ) : (
        <FileContainer>
          <Box mt={4} />
          <NoActionsBox>
            <img src={noFilesImg} height={400} alt={t('noFiles')} />
            <Typography variant="h5" color="primary">
              {t('noFiles')}
            </Typography>
          </NoActionsBox>
        </FileContainer>
      )}
      <ConfirmDialog
        title={confirmFileDeleteDialog?.title ?? ''}
        text={confirmFileDeleteDialog?.text ?? ''}
        dialogOpen={!!confirmFileDeleteDialog}
        onClose={confirmFileDeleteDialog?.cancel}
        onSubmit={confirmFileDeleteDialog?.callback}
      />
    </>
  );
}
