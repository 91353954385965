import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useRef
} from 'react';
import { Box, Button, Chip, Hidden, TextField } from '@material-ui/core';
import {
  CalendarTodayRounded as CalenderIcon,
  AddRounded as AddIcon
} from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@material-ui/pickers';
import styled from 'styled-components';

import { AuthContext, baseUrl, useFile, useSpot } from 'framework';
import { User } from 'domain-types';
import {
  Row,
  Container,
  FormTextInput,
  FormLabel,
  FormRow,
  CroppedImage,
  FormFieldWrapper,
  Line,
  TitleContainer,
  Capitalized,
  Gap,
  VerticalContainer
} from 'components';

const ScrollContainer = styled(Box)`
  max-height: 600px;
  overflow: auto;
`;

const CenteredVerticalContainer = styled(VerticalContainer)`
  align-items: center;
  flex: 1 1 auto;
`;

const StyledButton = styled(Button)`
  margin: ${p => p.theme.spacing(2, 2)};
  padding: ${p => p.theme.spacing(2)}px;
`;

const GroupChip = styled(Chip)`
  margin-right: ${p => `${p.theme.spacing(2)}px`};
  text-transform: capitalize;
`;

export function ProfileOverview({
  id,
  organization
}: {
  id: string;
  organization: string;
}) {
  const { t } = useTranslation();
  const fileInput = useRef<HTMLInputElement>(null);
  const { getToken, isAdministrator, getCurrentUser } = useContext(AuthContext);
  const { data, query, command } = useSpot();

  const user = data?.users?.[id];
  const avatar = user?.avatar;

  const refreshUser = useCallback(async () => {
    await query(`org/${organization}/user/${id}`, {}, ['users', id]);
  }, [organization, id, query]);

  useEffect(() => {
    query(`org/${organization}/search`, {}, ['searchUsers']);
  }, [query, organization]);

  useEffect(() => {
    refreshUser();
  }, [refreshUser, query, organization, id]);

  const { url: imageUrl } = useFile(avatar || '', organization, id);

  const updateUser = async (field: string, value: unknown) => {
    await command(`org/${organization}/user/${id}`, {
      [field]: value
    });
    await refreshUser();
  };

  const uploadAvatar = async (file: File) => {
    if (!file) {
      return;
    }

    // Fetch upload url
    const uploadResponse = await fetch(
      `${baseUrl}/org/${organization}/user/${id}/getUserFileUpload/${file.name}`,
      {
        headers: {
          authorization: getToken().getJwtToken()
        },
        method: 'get'
      }
    );

    const { url } = await uploadResponse.json();

    // Upload
    await fetch(url, {
      method: 'put',
      body: file
    });

    await command(`org/${organization}/user/${id}`, {
      avatar: `${file.name}`
    });

    await refreshUser();
  };

  const handleFileSelected = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.files?.length && uploadAvatar(e.target.files[0]);
  };

  const onRemoveGroup = async (group: string) => {
    await command(`org/${organization}/user/${id}/groups/remove`, { group });
    await refreshUser();
  };

  const onAddGroup = async (group: string) => {
    await command(`org/${organization}/user/${id}/groups/add`, { group });
    await refreshUser();
  };

  return (
    <ScrollContainer>
      <TitleContainer>
        <Capitalized variant="h5">{t('profile')}</Capitalized>
      </TitleContainer>
      <Line />
      <Row>
        <Container>
          <FormRow>
            <FormLabel>{t('email')}</FormLabel>
            <FormTextInput disabled initialValue={user?.email} />
          </FormRow>
          <FormRow>
            <FormLabel>{t('startDate')}</FormLabel>
            <FormFieldWrapper>
              <DatePicker
                value={
                  user?.startDate ? moment(user?.startDate, 'DD-MM-YYYY') : null
                }
                format="LL"
                openTo="year"
                fullWidth
                disabled={!isAdministrator()}
                showTodayButton
                placeholder={t('enterDob')}
                error={false}
                onChange={value =>
                  updateUser('startDate', value?.format('DD-MM-YYYY') ?? '')
                }
                InputProps={{
                  endAdornment: <CalenderIcon color="action" fontSize="small" />
                }}
              />
            </FormFieldWrapper>
          </FormRow>
          <FormRow>
            <FormLabel>{t('firstName')}</FormLabel>
            <FormTextInput
              initialValue={user?.firstName}
              onChange={(value: string) => updateUser('firstName', value)}
            />
          </FormRow>
          <FormRow>
            <FormLabel>{t('lastName')}</FormLabel>
            <FormTextInput
              initialValue={user?.lastName}
              onChange={(value: string) => updateUser('lastName', value)}
            />
          </FormRow>

          <FormRow>
            <FormLabel>{t('dob')}</FormLabel>
            <FormFieldWrapper>
              <DatePicker
                value={user?.dob ? moment(user?.dob, 'DD-MM-YYYY') : null}
                format="LL"
                openTo="year"
                fullWidth
                showTodayButton
                placeholder={t('enterDob')}
                error={false}
                onChange={value =>
                  updateUser('dob', value?.format('DD-MM-YYYY') ?? '')
                }
                InputProps={{
                  endAdornment: <CalenderIcon color="action" fontSize="small" />
                }}
              />
            </FormFieldWrapper>
          </FormRow>
          <FormRow>
            <FormLabel>{t('manager')}</FormLabel>
            <FormFieldWrapper>
              <Autocomplete
                disabled={!isAdministrator()}
                value={
                  user?.managerId ? data?.searchUsers?.[user?.managerId] : null
                }
                onChange={(event: unknown, newValue: User | null) =>
                  updateUser('managerId', newValue?.id ?? '')
                }
                options={Object.values(data?.searchUsers || {})}
                getOptionLabel={(manager: User) =>
                  `${manager.firstName} ${manager.lastName ?? ''}`
                }
                autoHighlight
                getOptionSelected={(o: User, v: User) => o?.id === v?.id}
                fullWidth
                renderInput={(params: unknown) => (
                  <TextField {...params} label="" />
                )}
              />
            </FormFieldWrapper>
          </FormRow>
          <FormRow>
            <FormLabel>{t('title')}</FormLabel>
            <FormTextInput
              disabled={!isAdministrator()}
              initialValue={user?.title}
              onChange={(value: string) => updateUser('title', value)}
            />
          </FormRow>
          <Gap />
          <TitleContainer>
            <Capitalized variant="h5">{t('address')}</Capitalized>
          </TitleContainer>
          <Line />
          <FormRow>
            <FormLabel>{t('line1')}</FormLabel>
            <FormTextInput
              initialValue={user?.address?.line1}
              onChange={(value: string) =>
                updateUser('address', {
                  line1: value
                })
              }
            />
          </FormRow>
          <FormRow>
            <FormLabel>{t('line2')}</FormLabel>
            <FormTextInput
              initialValue={user?.address?.line2}
              onChange={(value: string) =>
                updateUser('address', {
                  line2: value
                })
              }
            />
          </FormRow>
          <FormRow>
            <FormLabel>{t('postalCode')}</FormLabel>
            <FormTextInput
              initialValue={user?.address?.postalCode}
              onChange={(value: string) =>
                updateUser('address', {
                  postalCode: value
                })
              }
            />
          </FormRow>
          <FormRow>
            <FormLabel>{t('city')}</FormLabel>
            <FormTextInput
              initialValue={user?.address?.city}
              onChange={(value: string) =>
                updateUser('address', {
                  city: value
                })
              }
            />
          </FormRow>
          <FormRow>
            <FormLabel>{t('region')}</FormLabel>
            <FormTextInput
              initialValue={user?.address?.region}
              onChange={(value: string) =>
                updateUser('address', {
                  region: value
                })
              }
            />
          </FormRow>
          <FormRow>
            <FormLabel>{t('country')}</FormLabel>
            <FormTextInput
              initialValue={user?.address?.country}
              onChange={(value: string) =>
                updateUser('address', {
                  country: value
                })
              }
            />
          </FormRow>
          <Gap />
          <TitleContainer>
            <Capitalized variant="h5">{t('contact')}</Capitalized>
          </TitleContainer>
          <Line />
          <FormRow>
            <FormLabel>{t('primaryPhoneNumber')}</FormLabel>
            <FormTextInput
              initialValue={user?.primaryPhoneNumber}
              onChange={(value: string) =>
                updateUser('primaryPhoneNumber', value)
              }
            />
          </FormRow>
          <FormRow>
            <FormLabel>{t('alternativeEmail')}</FormLabel>
            <FormTextInput
              initialValue={user?.alternativeEmail}
              onChange={(value: string) =>
                updateUser('alternativeEmail', value)
              }
            />
          </FormRow>
          <Gap />
          <TitleContainer>
            <Capitalized variant="h5">{t('emergencyContact')}</Capitalized>
          </TitleContainer>
          <Line />
          <FormRow>
            <FormLabel>{t('name')}</FormLabel>
            <FormTextInput
              initialValue={user?.emergencyContact?.name}
              onChange={(value: string) =>
                updateUser('emergencyContact', { name: value })
              }
            />
          </FormRow>
          <FormRow>
            <FormLabel>{t('primaryPhoneNumber')}</FormLabel>
            <FormTextInput
              initialValue={user?.emergencyContact?.primaryPhoneNumber}
              onChange={(value: string) =>
                updateUser('emergencyContact', { primaryPhoneNumber: value })
              }
            />
          </FormRow>
          <FormRow>
            <FormLabel>{t('email')}</FormLabel>
            <FormTextInput
              initialValue={user?.emergencyContact?.email}
              onChange={(value: string) =>
                updateUser('emergencyContact', { email: value })
              }
            />
          </FormRow>
          <Gap />
        </Container>
        <CenteredVerticalContainer>
          <Hidden mdUp>
            <Gap />
            <Line />
          </Hidden>
          <StyledButton
            variant="outlined"
            onClick={() => fileInput.current?.click()}
          >
            <CroppedImage
              width={256}
              height={256}
              url={imageUrl}
              alt={`${user?.firstName} ${user?.lastName ?? ''}`}
            />
          </StyledButton>
          <input
            ref={fileInput}
            type="file"
            onChange={handleFileSelected}
            style={{ display: 'none' }}
          />
          <Box>
            {user?.groups?.map(group => (
              <GroupChip
                key={group}
                label={group}
                color="secondary"
                disabled={
                  !isAdministrator() ||
                  (group === 'administrators' && id === getCurrentUser())
                }
                onDelete={() => onRemoveGroup(group)}
              />
            ))}
            {isAdministrator() &&
              !user?.groups?.find(grp => grp === 'administrators') && (
                <Button
                  onClick={() => onAddGroup('administrators')}
                  startIcon={<AddIcon />}
                >
                  {t('addAdmin')}
                </Button>
              )}
          </Box>
        </CenteredVerticalContainer>
      </Row>
    </ScrollContainer>
  );
}
